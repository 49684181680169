const kr = {
  header: {
    home: "بيت",
    services: "خدمات",
    products: "منتجات",
    caseStudies: "دراسات الحالة",
    company: "شركة",
    career: "حياة مهنية",
    contact: "اتصال",
    english: "ENGLISH",
    kurdish: "عربي",
    anyBusinessIdea: "أي فكرة عمل",
  },
  footer: {
    titleAboutNewroz: "Newroz عن",
    aboutNewrozDetails:
      "تتخصص NewrozTech في سد الاحتياجات التنظيمية والإمكانيات التقنية، وقولبة الأفكار في أطر عمل رقمية. نحن نستفيد من إمكانات التكنولوجيا لتحقيق الرؤى، وتمكين الشركات بحلول متطورة تتجاوز التوقعات. ويضمن تفاني فريقنا تجارب استثنائية، وتحويل الرؤى المفاهيمية إلى واقع ملموس.",
    titleAbout: "عن",
    titleSoftwareEngineering: "هندسة البرمجيات",
    titleServicesWeProvide: "الخدمات التي نقدمها",
    titleCentersofExcellence: "مراكز التميز",
    titleOurProducts: "منتجاتنا",
    aboutUs: "معلومات عنا",
    blog: "مدونة",
    careers: "وظائف",
    jobs: "وظائف",
    services: "خدمات",
    Portfolio: "ورقة الملف الشخصي",
    webAppDevelopment: "تطوير تطبيقات الويب ",
    mobileAppDevelopment: "تطوير تطبيقات الجوال",
    cloudConsultancy: "الاستشارات السحابية",
    customSoftwareDevelopment: "تطوير البرمجيات المخصصة",
    UIUXDesign: "تصميم UI / UX",
    DevOpsServices: "خدمات ديف أوبس",
    inPress: "في الصحافة",
    technicalDebtReduction: "تخفيض الديون الفنية",
    roboticProcessAutomation: "أتمتة العمليات الروبوتية",
    aIAndMachineLearning: "AI & Machine Learning",
    chatbots: "الذكاء الاصطناعي والتعلم الآلي",
    contact: "معلومات",
    privacyPolicy: "سياسة الخصوصية",
    sitemap: "خريطة الموقع",
    termsOfUse: "شروط الاستخدام",
    allRightsReserved: "كل الحقوق محفوظة",
    cryptocurrenciesIQDX: "العملات المشفرة - IQDX",
    EKYCSolution: "حل KYC الإلكتروني",
    fastConnectPOS: "الاتصال السريع (نقاط البيع)",
    dalkurdFootballAssociation: "اتحاد دالكرد لكرة القدم",
  },
  homeTopSetion: {
    title: "التمكين",
    subtitle: "الأعمال مع التكنولوجيا المتقدمة",
    details:
      "نحن فريق من الأفراد المتحمسين الذين يذهبون إلى أبعد من ذلك لخلق تجارب رائعة لعملك.",
    connectWithUs: "اتصل بنا",
    howItWorks: "كيف تعمل",
    animationText: "We are here to help you achieve your success.",
  },
  homeService: {
    title: "خدمات",
    subtitle: "اختر أفضل شركة لخدمات تكنولوجيا المعلومات",
    details:
      "عند تصميم حل ، نجعل حل المشكلات أولويتنا القصوى مع الحفاظ على نفس القدر من التركيز على الجماليات.",

    // card1: {
    //   title: "خدمات",
    //   subtitle: "اختر أفضل شركة لخدمات تكنولوجيا المعلومات",
    //   details:
    //     "عند تصميم حل ، نجعل حل المشكلات أولويتنا القصوى مع الحفاظ على نفس القدر من التركيز على الجماليات.",
    //   buttonText: "إكتشف أكثر",
    // },
    card1: {
      title: "تطوير تطبيقات الهاتف المحمول",
      subtitle: "اختر أفضل حلول الهاتف المحمول",
      details:
        "بالتمسك بفلسفة المحمول أولاً ، نقوم بوضع تصور وتصميم ونشر مبتكر تطبيقات الهاتف المحمول عبر منصات مختلفة. هدفنا هو توفير مستخدم سلس الخبرات ، مما يوسع نطاق وصول أعمالك في السوق الذي يتزايد اعتماده على الأجهزة المحمولة.",
      servicesDetails: {
        cardSection1: {
          title: "الذي نفعله؟",
          card1: {
            title: "تطوير تطبيقات iOS",
            details:
              "يصمم مطورونا المهرة تطبيقات غنية بالميزات وتركز على المستخدم، باستخدام أحدث إمكانات iOS. سواء كان الأمر يتعلق بإنشاء تطبيق جديد من البداية أو تحسين تطبيق حالي ، فإننا نضمن تجربة مستخدم سلسة ، والالتزام بإرشادات التصميم ، والتكامل مع ميزات الجهاز.",
          },
          card2: {
            title: "تطوير تطبيقات أندرويد",
            details:
              "نحن متخصصون في تطوير تطبيقات Android التي تحول مفاهيمك إلى تجارب جوال ديناميكية وجذابة. يستفيد فريق الخبراء لدينا من تعدد استخدامات نظام Android الأساسي لتصميم وتطوير التطبيقات المصممة خصيصًا لتلبية احتياجات عملك.",
          },
          card3: {
            title: "تطوير التطبيقات عبر الأنظمة الأساسية",
            details:
              "نحن نتفوق في تطوير التطبيقات عبر الأنظمة الأساسية ، حيث نستغل قوة التكنولوجيا لإنشاء تطبيقات متعددة الاستخدامات تعمل بسلاسة عبر منصات متعددة. يستخدم المطورون المهرة لدينا أدوات وأطر عمل متطورة لإنشاء تطبيقات تقدم تجربة مستخدم متسقة على كل من أجهزة iOS و Android.",
          },
          card4: {
            title: "تطوير تطبيقات الجوال Flutter",
            details:
              "نستخدم Flutter لإنشاء تطبيقات تعمل بسلاسة عبر أجهزة iOS و Android ، مما يضمن أداءً متسقًا وتجربة مستخدم جذابة. من المفهوم إلى النشر ، نعطي الأولوية للابتكار وجماليات التصميم والوظائف الفعالة لتقديم التطبيقات التي تبرز في المشهد المحمول التنافسي.",
          },

          card5: {
            title: "استشارات تطبيقات الهاتف المحمول",
            details:
              "إن استشارات تطبيقات الهاتف المحمول في شركتنا هي طريقك إلى تطوير التطبيقات بنجاح. يقدم مستشارونا المتمرسون رؤى واستراتيجيات وإرشادات فنية قيمة لتحويل فكرة تطبيق الهاتف المحمول الخاص بك إلى حل ناجح.",
          },
          card6: {
            title: "رد فعل تطوير التطبيقات الأصلية",
            details:
              "نحن نلتزم بتطوير تطبيق React Native ، حيث نقدم حلولًا متعددة الاستخدامات وفعالة تعمل على سد الفجوة بين الأداء المحلي والقدرات عبر الأنظمة الأساسية. من تصور الفكرة إلى النشر ، تعطي خدمات تطوير التطبيقات التابعة لـ React Native الأولوية للأداء والاستجابة ومشاركة المستخدم.",
          },
        },
        cardSection2: {
          title: "عملية التصميم والتطوير",
          card1: {
            title: "إستراتيجية",
          },
          card2: {
            title: "التحليل والتخطيط",
          },
          card3: {
            title: "تصميم",
          },
          card4: {
            title: "تطوير",
          },
          card5: {
            title: "اختبارات",
          },
          card6: {
            title: "النشر والدعم",
          },
        },
      },
    },
    card2: {
      title: "تطوير البرمجيات المخصصة",
      subtitle: "اختر أفضل حل لتطوير البرمجيات المخصصة",
      details:
        "إدراكًا للطبيعة الفريدة لكل عمل ، نتفوق في ابتكار حلول برمجية مخصصة. لا تتوافق عروضنا مع عملياتك وأهدافك فحسب، بل إنها مصممة لتوفير ميزة تنافسية متميزة في مجال عملك.",
      servicesDetails: {
        cardSection1: {
          title: "الذي نفعله؟",
          card1: {
            title: "MVP للشركات الناشئة",
            details:
              "اصنع الحد الأدنى من المنتجات القابلة للتطبيق (MVPs) لبدء المشاريع الرقمية للشركات الناشئة. يركز تطوير البرامج المخصصة لدينا على النماذج الأولية السريعة، وتقديم الميزات الأساسية للاختبار والتحقق من الصحة، وضمان فعالية التكلفة ودخول السوق بشكل أسرع.",
          },
          card2: {
            title: "عمليات التطوير والأتمتة",
            details:
              "قم بتبسيط عمليات التطوير باستخدام ممارسات DevOps وأدوات التشغيل الآلي. نحن ندمج التكامل المستمر والتسليم المستمر (CI/CD) والنشر الآلي لتحسين جودة البرامج وتسريع الإصدارات وتحسين التعاون.",
          },
          card3: {
            title: "فحص الكود",
            details:
              "قم بمراجعة التعليمات البرمجية بدقة لتحديد نقاط الضعف وتحسين جودة التعليمات البرمجية وضمان الالتزام بمعايير الترميز. تساعد خدمات فحص التعليمات البرمجية لدينا على تحديد المشكلات المحتملة في وقت مبكر من دورة التطوير، مما يقلل من جهود الصيانة المستقبلية ويعزز موثوقية البرامج بشكل عام.",
          },
          card4: {
            title: "الاختبار الآلي",
            details:
              "تنفيذ إستراتيجيات اختبار آلية شاملة لضمان وظائف البرنامج وأدائه وأمانه. يشتمل تطوير البرامج المخصصة لدينا على الوحدة الآلية والتكامل واختبار الانحدار لاكتشاف المشكلات بسرعة، مما يتيح تكرارات أسرع وتحسين جودة البرامج.",
          },
          card5: {
            title: "إدارة النظام",
            details:
              "تقديم الدعم المستمر من خلال خدمات إدارة النظام. يقوم خبراؤنا بمراقبة وإدارة وتحسين أداء نظام البرمجيات وأمانه وقابلية التوسع، مما يسمح للشركات بالتركيز على عملياتها الأساسية مع ضمان بيئة برمجية موثوقة.",
          },
        },
        cardSection2: {
          title: "عملية التصميم والتطوير",
          card1: {
            title: "التحليل والتخطيط",
          },
          card2: {
            title: "تصميم",
          },
          card3: {
            title: "تطوير",
          },
          card4: {
            title: "تطبيق",
          },
          card5: {
            title: "اختبارات",
          },
          card6: {
            title: "صيانة",
          },
        },
      },
    },
    card3: {
      title: "خدمات التطوير",
      subtitle: "اختر أفضل خدمات المطورين",
      details:
        "تعمل خدمات DevOps الخاصة بنا على تبسيط تسليم البرامج ونشرها ، مما يتيح التعاون الفعال وفرق العمليات.",
      servicesDetails: {
        cardSection1: {
          title: "الذي نفعله؟",
          card1: {
            title: "التكامل المستمر",
            details:
              "قم بأتمتة عملية دمج تغييرات التعليمات البرمجية في مستودع مشترك، مما يضمن اختبار التعليمات البرمجية الجديدة بشكل منتظم وتلقائي، مما يقلل من مشكلات التكامل ويعزز الاكتشاف المبكر للأخطاء.",
          },
          card2: {
            title: "التسليم المستمر",
            details:
              "قم بتوسيع CI من خلال أتمتة عملية النشر في بيئات التدريج والإنتاج. يضمن القرص المضغوط نشر التعليمات البرمجية التي تم اختبارها باستمرار في بيئات مختلفة، مما يقلل من الأخطاء اليدوية ويتيح دورات إصدار أسرع.",
          },
          card3: {
            title: "إدارة التكوين",
            details:
              "إدارة وأتمتة تكوينات المكونات المختلفة في بيئة البرنامج. وهذا يضمن الاتساق عبر البيئات المختلفة ويقلل من جهود الإعداد اليدوي.",
          },
          card4: {
            title: "الاختبار الآلي",
            details:
              "دمج ممارسات الاختبار الآلي طوال دورة حياة التطوير، بما في ذلك اختبارات الوحدة واختبارات التكامل واختبارات الأداء واختبارات الأمان. يساعد الاختبار الآلي على اكتشاف العيوب مبكرًا ويضمن جودة البرنامج.",
          },
          card5: {
            title: "المراقبة والتسجيل",
            details:
              "قم بإعداد أدوات المراقبة لتتبع أداء التطبيق وسلامة البنية التحتية وتجربة المستخدم. يساعد التسجيل والمراقبة المركزيان في تحديد المشكلات وحلها بسرعة.",
          },
          card6: {
            title: "تحسن مستمر",
            details:
              "تبني ثقافة التحسين المستمر من خلال تحليل المقاييس وتعليقات المستخدمين وبيانات الأداء. قم بمراجعة عمليات DevOps وتحسينها بانتظام لتحسين تسليم البرامج والكفاءة التشغيلية.",
          },
        },
        cardSection2: {
          title: "عملية التصميم والتطوير",
          card1: {
            title: "عملية",
          },
          card2: {
            title: "تطوير",
          },
          card3: {
            title: "اندماج",
          },
          card4: {
            title: "اختبارات",
          },
          card5: {
            title: "يراقب",
          },
          card6: {
            title: "تعيين",
          },
        },
      },
    },
    card4: {
      title: "بلوكشين",
      subtitle: "اختر أفضل خدمات Blockchain",
      details:
        "من خلال تسخير الإمكانات التحويلية لتقنية blockchain، نقوم ببناء تطبيقات لا مركزية وعقود ذكية. وتوفر هذه الحلول حلولاً آمنة وشفافة ومضادة للتلاعب، وتلبي مجموعة متنوعة من احتياجات الصناعة.",
      servicesDetails: {
        cardSection1: {
          title: "الذي نفعله؟",
          card1: {
            title: "حماية",
            details:
              "يتم تأمين المعاملات من خلال تقنيات التشفير. ترتبط كل معاملة بالمعاملة السابقة، مما يشكل سلسلة من الكتل، مما يجعلها شديدة المقاومة للقرصنة والاحتيال.",
          },
          card2: {
            title: "تناسق البيانات",
            details:
              "تضمن آليات الإجماع موافقة جميع المشاركين على حالة البيانات. وهذا يمنع التناقضات ويضمن نسخة واحدة من الحقيقة.",
          },
          card3: {
            title: "قاعدة البيانات الموزعة",
            details:
              "يتم توزيع البيانات عبر عقد متعددة في الشبكة، مما يؤدي إلى تحسين التكرار والتسامح مع الأخطاء. وهذا يقلل من خطر فقدان البيانات بسبب نقطة فشل واحدة.",
          },
          card4: {
            title: "التوافقية",
            details:
              "تركز بعض منصات blockchain على قابلية التشغيل البيني، مما يسمح لأنظمة blockchain المختلفة بالتواصل ومشاركة البيانات، مما يتيح تطبيقات وشبكات أكثر تعقيدًا.",
          },
          card5: {
            title: "التدقيق والامتثال",
            details:
              "إن طبيعة blockchain الشفافة والقابلة للتدقيق تجعلها مناسبة للصناعات ذات المتطلبات التنظيمية الصارمة، مثل التمويل والرعاية الصحية.",
          },
          card6: {
            title: "تأثير بيئي",
            details:
              "تستخدم بعض سلاسل الكتل خوارزميات توافقية موفرة للطاقة، مما يجعلها أكثر صداقة للبيئة مقارنة بالأنظمة التقليدية.",
          },
        },
        cardSection2: {
          title: "عملية التصميم والتطوير",
          card1: {
            title: "شبكة الكمبيوتر P2P",
          },
          card2: {
            title: "التحقق من صحة العقدة",
          },
          card3: {
            title: "تَحَقّق",
          },
          card4: {
            title: "إنشاء الكتلة",
          },
          card5: {
            title: "إنشاء بلوكتشين",
          },
          card6: {
            title: "مكتمل",
          },
        },
      },
    },
    card5: {
      title: "خدمات اختبار البرمجيات",
      subtitle: "اختر أفضل خدمة لاختبار البرمجيات",
      details:
        "تشكل الجودة والموثوقية حجر الأساس لعروض برامجنا. اختباراتنا الماهرة يستفيد الفريق من منهجيات الاختبار الصارمة لتحديد المشكلات وتصحيحها ، مما يضمن تقديم تطبيقات قوية وخالية من العيوب.",
      servicesDetails: {
        cardSection1: {
          title: "الذي نفعله؟",
          card1: {
            title: "استشارات ضمان الجودة",
            details:
              "تتضمن استشارات ضمان الجودة خبراء يقدمون المشورة والتوجيه لتحسين عمليات ضمان الجودة. يقومون بتقييم استراتيجيات الاختبار الحالية، واقتراح التحسينات، ومساعدة المؤسسات على تنفيذ أفضل الممارسات.",
          },
          card2: {
            title: "ضمان الجودة المستقل",
            details:
              "يتضمن ضمان الجودة المستقل فريقًا أو كيانًا منفصلاً يقوم بإجراء الاختبارات دون أي مشاركة في تطوير البرنامج. وهذا يضمن إجراء تقييم غير متحيز، والعثور على العيوب التي قد يتجاهلها فريق التطوير.",
          },
          card3: {
            title: "اختبار متكامل",
            details:
              "يركز الاختبار المتكامل على اختبار التفاعلات بين المكونات أو الوحدات المختلفة لنظام البرنامج. فهو يضمن أن المكونات المتكاملة تعمل كما هو متوقع، وتحدد المشكلات التي قد تنشأ عندما تجتمع أجزاء مختلفة من النظام معًا.",
          },
          card4: {
            title: "الاختبار الآلي",
            details:
              "يتضمن الاختبار الآلي استخدام البرامج النصية والأدوات لتنفيذ حالات الاختبار تلقائيًا. إنه يعزز سرعة الاختبار والدقة والتكرار. وهذا مفيد بشكل خاص لاختبار الانحدار والمهام المتكررة.",
          },
          card5: {
            title: "اختبار الدورة الكاملة",
            details:
              "يغطي اختبار الدورة الكاملة دورة حياة تطوير البرمجيات بأكملها، بدءًا من تحليل المتطلبات وحتى صيانة ما بعد الإصدار. يتضمن مراحل اختبار مختلفة مثل اختبار الوحدة واختبار التكامل واختبار النظام واختبار القبول والمزيد.",
          },
        },
        cardSection2: {
          title: "عملية التصميم والتطوير",
          card1: {
            title: "اختبار تجربة المستخدم",
          },
          card2: {
            title: "اختبار الوظائف",
          },
          card3: {
            title: "اختبار حمل الأداء",
          },
          card4: {
            title: "اختبار التوافق",
          },
          card5: {
            title: "إدارة الاختبار",
          },
          card6: {
            title: "اختبار الأمان",
          },
        },
      },
    },
    card6: {
      title: "تطوير تطبيقات الويب",
      subtitle: "اختر أفضل نظام لتطوير تطبيقات الويب",
      details:
        "مع وجود فريق من المطورين المتمرسين تحت تصرفنا ، نقوم ببناء قوي وسهل الاستخدام تطبيقات الويب ، المصممة بدقة لتلائم احتياجات عملك. من مواقع الويب المبسطة لبوابات الويب المعقدة ، تعمل حلولنا كمحفزات للنمو والكفاءة.",
      servicesDetails: {
        cardSection1: {
          title: "الذي نفعله؟",
          card1: {
            title: "خطة المشروع",
            details:
              "يتضمن إنشاء خطة المشروع تحديد النطاق والجدول الزمني والموارد والمعالم الرئيسية لتطوير تطبيق الويب.",
          },
          card2: {
            title: "تحليل الاحتياجات",
            details:
              "يتضمن تحليل المتطلبات فهم احتياجات العميل وأهدافه لتطبيق الويب. ويشمل جمع وتوثيق وتحسين متطلبات المشروع الوظيفية وغير الوظيفية",
          },
          card3: {
            title: "إعداد البيئة",
            details:
              "يتضمن إعداد البيئة تكوين بيئات التطوير والاختبار والنشر الضرورية. يتضمن ذلك إعداد الخوادم وقواعد البيانات والأطر والأدوات اللازمة لتطوير واختبار تطبيق الويب.",
          },
          card4: {
            title: "التطوير وضمان الجودة",
            details:
              "خلال مرحلة التطوير، يتم الترميز الفعلي لتطبيق الويب بناءً على المتطلبات. يقوم المطورون بكتابة التعليمات البرمجية وإنشاء الوظائف ودمج المكونات.",
          },
          card5: {
            title: "دمج الكود",
            details:
              "يتضمن دمج التعليمات البرمجية دمج تغييرات التعليمات البرمجية التي أجراها مطورون مختلفون في قاعدة تعليمات برمجية مشتركة. يتم ذلك عادةً باستخدام أنظمة التحكم في الإصدار مثل Git. يؤدي دمج التعليمات البرمجية بشكل صحيح إلى منع حدوث تعارضات ويضمن عمل المكونات المختلفة للتطبيق بشكل متناغم.",
          },
          card6: {
            title: "الإطلاق ودعم ما بعد الإطلاق",
            details:
              "تتضمن مرحلة الإطلاق نشر تطبيق الويب في بيئة الإنتاج وإتاحته للمستخدمين. يتضمن دعم ما بعد الإطلاق مراقبة أداء التطبيق ومعالجة أي مشكلات أو أخطاء تنشأ وتوفير تحديثات وتحسينات مستمرة لتحسين تجربة المستخدم.",
          },
        },
        cardSection2: {
          title: "عملية التصميم والتطوير",
          card1: {
            title: "تحليل",
          },
          card2: {
            title: "الإطار السلكي",
          },
          card3: {
            title: "تصميم",
          },
          card4: {
            title: "تطوير",
          },
          card5: {
            title: "تعيين",
          },
          card6: {
            title: "صيانة",
          },
        },
      },
    },
    card7: {
      title: "تصميم UI / UX",
      subtitle: "اختر أفضل خدمة UI/UX",
      details:
        "يركز فريقنا من مصممي UI / UX المبتكرين على خلق آسر بصري و واجهات تتمحور حول المستخدم. تم تصميمها ليس فقط لتعزيز رضا المستخدم ولكن أيضًا تعزيز اعتماد المستخدم ، وتكوين تجارب رقمية استثنائية.",
      servicesDetails: {
        cardSection1: {
          title: "الذي نفعله؟",
          card1: {
            title: "موجز التصميم",
            details:
              "ملخص التصميم هو وثيقة أولية تحدد أهداف المشروع وغاياته والجمهور المستهدف وتفضيلات التصميم. إنه بمثابة الأساس لعملية التصميم ويساعد على مواءمة فريق التصميم وأصحاب المصلحة.",
          },
          card2: {
            title: "نمط التصميم",
            details:
              "يتضمن اختيار نمط التصميم اختيار النمط المرئي الذي يتناسب مع غرض المشروع والجمهور. ويتضمن قرارات بشأن أنظمة الألوان والطباعة والصور وعناصر التصميم الأخرى التي تعكس هوية العلامة التجارية.",
          },
          card3: {
            title: "الإطارات السلكية",
            details:
              "الإطارات السلكية هي تمثيلات مرئية أساسية لتخطيط واجهة المستخدم وبنيتها. وهي تحدد موضع العناصر الأساسية، مثل الأزرار وأقسام المحتوى، مما يساعد على تخطيط تجربة المستخدم وتدفق التصميم بشكل عام.",
          },
          card4: {
            title: "تصميم واجهة المستخدم وتجربة المستخدم",
            details:
              "يركز تصميم واجهة المستخدم (UI) على إنشاء واجهات وظيفية وجذابة. ويتضمن تصميم الأزرار والقوائم والأيقونات والعناصر الأخرى التي يتفاعل معها المستخدمون. يتضمن تصميم تجربة المستخدم (UX) تعزيز سهولة الاستخدام ورضا المستخدم من خلال تصميم التنقل البديهي وسير العمل الواضح.",
          },
          card5: {
            title: "تسليم التصميم",
            details:
              "تتضمن مخرجات التصميم أصول التصميم النهائية التي سيتم استخدامها في مرحلة التطوير. قد يتضمن ذلك نماذج بالحجم الطبيعي عالية الدقة وملفات التصميم وأدلة الأنماط وأي موارد أخرى ضرورية لإضفاء الحيوية على رؤية التصميم أثناء التطوير.",
          },
        },
        cardSection2: {
          title: "عملية التصميم والتطوير",
          card1: {
            title: "المشكلة والمفاهيم",
          },
          card2: {
            title: "احتياجات المستخدم",
          },
          card3: {
            title: "تصميم التعاطف",
          },
          card4: {
            title: "التخطيط السلكي",
          },
          card5: {
            title: "التصميم المرئي",
          },
          card6: {
            title: "النماذج الأولية",
          },
        },
      },
    },
    card8: {
      title: "خدمات التحقق من E-KYC",
      subtitle: "اختر أفضل خدمة للتحقق من KYC",
      details:
        "في عصر يعد فيه الامتثال التنظيمي أمرًا بالغ الأهمية ، توفر خدمات التحقق من E-KYC لدينا نهج مبسط وفعال للتحقق من الهوية. وهذا يساعد على تلبية صارمة معايير الامتثال وتحمي عملك من المخاطر المحتملة.",
      servicesDetails: {
        cardSection1: {
          title: "الذي نفعله؟",
          card1: {
            title: "التحقق من الوثيقة",
            details:
              "يقوم النظام تلقائيًا بالتحقق من وثائق الهوية مثل بطاقات الهوية وجوازات السفر ورخص القيادة للتأكد من صحتها ودقتها.",
          },
          card2: {
            title: "تمييز الوجوه",
            details:
              "يُطلب من المستخدمين تقديم صورة شخصية تتم مقارنتها بالصورة الموجودة في وثيقة الهوية الخاصة بهم للتحقق من الهوية.",
          },
          card3: {
            title: "التحقق في الوقت الحقيقي",
            details: "نتائج التحقق الفورية لضمان تجربة مستخدم سلسة.",
          },
          card4: {
            title: "خيارات التكامل",
            details:
              "واجهات برمجة التطبيقات (APIs) ومجموعات SDK (SDK) لسهولة التكامل في الأنظمة والأنظمة الأساسية الحالية.",
          },
          card5: {
            title: "التعامل الآمن مع البيانات",
            details:
              "بروتوكولات أمان البيانات الصارمة لحماية معلومات المستخدم.",
          },
          card6: {
            title: "دقة البيانات",
            details: "دقة عالية في استخراج المستندات والتحقق من النتائج.",
          },
          card7: {
            title: "قابلية التوسع",
            details:
              "قادر على التعامل مع كميات كبيرة من طلبات التحقق مع تزايد أعداد المستخدمين.",
          },
          card8: {
            title: "التغطية العالمية",
            details:
              "دعم مجموعة واسعة من أنواع المستندات والبلدان لتلبية احتياجات قاعدة مستخدمين متنوعة.",
          },
          card9: {
            title: "دعم العملاء",
            details:
              "تقديم المساعدة للمستخدمين الذين يواجهون مشكلات أثناء عملية التحقق.",
          },
        },
        cardSection2: {
          title: "عملية التصميم والتطوير",
          card1: {
            title: "التحقق من الاتصال",
          },
          card2: {
            title: "مصادقة الوجه",
          },
          card3: {
            title: "تحليل الاحتيال",
          },
          card4: {
            title: "تحقق مع خادم NID",
          },
          card5: {
            title: "معلومات إضافية",
          },
          card6: {
            title: "فحص العقوبات",
          },
        },
      },
    },
    card9: {
      title: "الاستشارات السحابية",
      subtitle: "اختر أفضل الاستشارات للخدمات السحابية",
      details:
        "نحن نسخر القوة التحويلية للتقنيات السحابية لمنح الشركات ميزة. يرشدك خبراؤنا خلال كل خطوة من خطوات العملية ، بدءًا من الترحيل و تصميم معماري لتحسين الأمان ، مما يضمن لك الاستفادة من قابلة للتطوير وآمنة الحلول السحابية.",
      servicesDetails: {
        cardSection1: {
          title: "الذي نفعله؟",
          card1: {
            title: "تطوير الإستراتيجية",
            details:
              "مساعدة الشركات على صياغة استراتيجية سحابية مخصصة تتماشى مع أهدافها ومتطلباتها.",
          },
          card2: {
            title: "التصميم المعماري",
            details:
              "تصميم بنيات سحابية قابلة للتطوير وموثوقة وفعالة من حيث التكلفة تستفيد من الإمكانات الكاملة للتقنيات السحابية.",
          },
          card3: {
            title: "تحسين التكلفة",
            details:
              "تحليل استخدام الموارد السحابية وتوفير استراتيجيات لتحسين التكاليف دون التضحية بالأداء.",
          },
          card4: {
            title: "تحسين الأداء",
            details:
              "ضبط التطبيقات السحابية والبنية التحتية لتحقيق الأداء الأمثل والاستجابة.",
          },
          card5: {
            title: "تكامل DevOps",
            details:
              "دمج ممارسات DevOps في البيئات السحابية لتمكين تطوير التطبيقات ونشرها بشكل أسرع.",
          },
          card6: {
            title: "تحسن مستمر",
            details:
              "تقديم استشارات مستمرة لضمان تطور البيئة السحابية مع احتياجات العمل المتغيرة والتقدم التكنولوجي.",
          },
          card7: {
            title: "استراتيجية السحابة المتعددة",
            details:
              "تقديم المشورة بشأن تنفيذ نهج متعدد السحابة لتوزيع أعباء العمل عبر موفري الخدمات السحابية المختلفين.",
          },
          card8: {
            title: "إدارة",
            details:
              "إنشاء أطر الحوكمة وممارسات الإدارة للعمليات السحابية الفعالة.",
          },
        },
        cardSection2: {
          title: "عملية التصميم والتطوير",
          card1: {
            title: "الاستشارة السحابية",
          },
          card2: {
            title: "الهجرة",
          },
          card3: {
            title: "التشغيل والتحسين",
          },
          card4: {
            title: "تحليل",
          },
          card5: {
            title: "الأمن السحابي",
          },
          card6: {
            title: "التحديث والتطوير",
          },
        },
      },
    },
    card10: {
      title: "فريق التطوير المخصص",
      subtitle: "اختر أفضل فريق لتطوير البرمجيات",
      details:
        "يعد نموذج تطوير البرمجيات المخصص بديلاً فعالاً من حيث التكلفة لنهج التطوير الداخلي. هذا هو المكان الذي تتألق فيه فرق التطوير المخصصة حقًا، مما يمنحك أفضل ما في العالمين - توافر المواهب وجودة الموارد، مما يتيح لك بناء فريق من شأنه أن يكون على قدم المساواة مع فريقك الداخلي. هذه الحقيقة تتناسب مع الطلب المتزايد على مطوري البرامج المتخصصين. والنتيجة النهائية لمشاركاتنا الاستشارية الإستراتيجية هي إستراتيجيات تكنولوجية قابلة للتنفيذ ومرتكزة على الواقع ومرتبطة بالأهداف التجارية للشركة. ولضمان ذلك، نقوم بتخصيص فريق من الخبراء متعددي التخصصات لكل عميل.",
      servicesDetails: {
        cardSection1: {
          title: "الذي نفعله؟",
          card1: {
            title: "فريق المشروع الخبراء",
            details:
              "تريد تعيين فريق مشروع مخصص لتوسيع القائمة أو إنشاء فريق جديد من الخبراء.",
          },
          card2: {
            title: "الحفاظ على التقدم على المدى الطويل",
            details: "مشروعك طويل الأمد وتخطط لتوسيع نطاقه بتقدم مستمر.",
          },
          card3: {
            title: "التنقل في الحلول التكنولوجية",
            details:
              "أنت تبحث عن الحلول التقنية المناسبة لمنتجك وتفتقر إلى الخبرة المهنية.",
          },
          card4: {
            title: "تمكين إدارة المشاريع",
            details:
              "أنت تريد التحكم في تدفق المشروع وإدارة الفريق المخصص بشكل فردي، وتكون على دراية بكل شيء يحدث.",
          },
          card5: {
            title: "إدارة نطاق المشروع",
            details:
              "متطلباتك غير واضحة ومن المؤكد أنها قابلة للتوسيع مما قد يؤثر من وقت لآخر على نطاق المشروع.",
          },
          card6: {
            title: "فتح الخبرة",
            details:
              "هناك سبب آخر للعمل مع فريق متخصص وهو وجود مجموعة متنوعة من المتخصصين في مجموعة المواهب العالمية.",
          },
        },
        cardSection2: {
          title: "Design and Development process",
          card1: {
            title: "التحضير",
          },
          card2: {
            title: "يثبت",
          },
          card3: {
            title: "اختبارات",
          },
          card4: {
            title: "يطلق",
          },
          card5: {
            title: "توسع",
          },
          card6: {
            title: "عملية",
          },
        },
      },
    },
  },
  homeIdea: {
    title1: "لديك أي فكرة!",
    title2: "يعمل معا",
    subtitle: "لقد شركات الشركات الناشئة في جميع أنحاء العالم",
    details:
      "هل تجلس على فكرة رائعة / رائعة؟ راجع فريق NewrozTech ، فريق العمل معه ونحوله إلى حقيقة حلمك!",
    productName1: "الواقع الممتد",
    productName2: "بلوك تشين",
    productName3: "تطبيق الهاتف المحمول",
    productName4: "التكنولوجيا المالية",
    productName5: "تطوير",
    productName6: "تصميم",
    productName7: "تسويق",
    productName8: "تطبيق الويب",
  },
  homeProduct: {
    title: "المنتجات",
    subtitle: "حلول مبتكرة لعالم رقمي",
    card1: {
      title: "محفظة FastPay الإلكترونية",
      details:
        "نحن نقدم أحدث خدمات تطوير المحفظة الإلكترونية عبر Android وiPhone و منصات متصفح الويب. تطبيقاتنا الآمنة، المليئة بالميزات، وسهلة الاستخدام، مُثرية مع تصميمات UI/UX المتميزة، ستدفعك إلى طليعة المدفوعات الرقمية ثورة.",
      info: {
        title: "محفظة FastPay الإلكترونية",
        subtitle: "تطور المدفوعات الرقمية",
        details:
          "نحن نقدم أحدث خدمات تطوير المحفظة الإلكترونية عبر Android وiPhone و منصات متصفح الويب. تطبيقاتنا الآمنة، المليئة بالميزات، وسهلة الاستخدام، مُثرية مع تصميمات UI/UX المتميزة، ستدفعك إلى طليعة المدفوعات الرقمية ثورة.",
        featuresTitle: "سمات",
        features: [
          "مزامنة الحسابات",
          "تاريخ المعاملات",
          "التسجيل وتسجيل الدخول",
          "التحويل من المحفظة إلى البنك",
          "تجديد الرصيد",
          "مرفق النسخ الاحتياطي",
          "لافتات",
          "التحقق من رصيد المحفظة",
          "المدفوعات عبر الإنترنت",
        ],
        problemStatementsTitle: "بيانات المشكلة:",
        problemStatements: [
          {
            textBold: "واجهة المستخدم غير الفعالة (UI):",
            text: "واجهة المستخدم الحالية لتطبيق الهاتف المحمول FastPay e-Wallet غير فعالة ويصعب التنقل فيها، مما يؤدي إلى تجربة مستخدم سيئة وإحباط محتمل للمستخدم.",
          },
          {
            textBold: "بطء الأداء:",
            text: "يعاني التطبيق من بطء الأداء، مما يتسبب في تأخير معالجة المعاملات والاستجابة العامة، مما يؤدي إلى عدم رضا المستخدم.",
          },
          {
            textBold: "الافتقار إلى التدابير الأمنية:",
            text: "يفتقر تطبيق المحفظة الإلكترونية FastPay إلى التدابير الأمنية القوية، مما يجعل المستخدمين عرضة لانتهاكات البيانات المحتملة والوصول غير المصرح به إلى معلوماتهم المالية الحساسة.",
          },
          {
            textBold: "نقص دعم العملاء:",
            text: "يواجه المستخدمون صعوبات في الحصول على دعم العملاء الفعال وفي الوقت المناسب عند مواجهة مشكلات أو الحاجة إلى المساعدة في التطبيق، مما يؤدي إلى تجربة مستخدم سيئة.",
          },
          {
            textBold: "مشكلات التوافق:",
            text: "يواجه تطبيق الهاتف المحمول FastPay e-Wallet مشكلات التوافق مع بعض الأجهزة المحمولة وأنظمة التشغيل، مما يمنع بعض المستخدمين من الوصول إلى التطبيق واستخدامه.",
          },
        ],
        solutionsTitle: "حلول:",
        solutions: [
          {
            textBold: "إعادة تصميم واجهة المستخدم (UI):",
            text: "استثمر في إصلاح واجهة المستخدم، مع التركيز على مبادئ التصميم سهلة الاستخدام والتنقل البديهي لتعزيز تجربة المستخدم الشاملة.",
          },
          {
            textBold: "تحسين الأداء:",
            text: "إجراء اختبار شامل للأداء وتنفيذ التحسينات اللازمة لضمان عمل التطبيق بسلاسة وكفاءة.",
          },
          {
            textBold: "تعزيز التدابير الأمنية::",
            text: "تنفيذ بروتوكولات أمنية قوية، بما في ذلك التشفير والمصادقة الثنائية، لحماية البيانات المالية للمستخدمين وتعزيز أمان التطبيقات بشكل عام.",
          },
          {
            textBold: "تحسين دعم العملاء:",
            text: "إنشاء نظام دعم عملاء سريع الاستجابة، بما في ذلك الدردشة المباشرة والبريد الإلكتروني وخط المساعدة المخصص، لمعالجة استفسارات المستخدمين ",
          },
          {
            textBold: "ضمان التوافق:",
            text: "قم بإجراء اختبارات توافق واسعة النطاق للتأكد من أن التطبيق يعمل بسلاسة عبر الأجهزة وأنظمة التشغيل المختلفة.",
          },
        ],
        productScreensTitle: "شاشات المنتج:",
        productScreens: [
          {
            img: "/images/Ewallet_login.png",
            title: "تسجيل الدخول",
            details:
              "للوصول إلى التطبيق، يمكن للمستخدمين تسجيل الدخول بسهولة باستخدام رقم الهاتف المحمول وكلمة المرور الخاصة بهم. ما عليك سوى إدخال رقم هاتفك المحمول المسجل وكلمة المرور المقابلة للوصول إلى حسابك بشكل آمن والاستمتاع بتجربة دفع سلسة.",
          },
          {
            img: "/images/Ewallet_home_screen.png",
            title: "الشاشة الرئيسية",
            details:
              "يعرض رصيدك المتاح بشكل بارز، مما يوفر نظرة سريعة على أموالك. بالإضافة إلى ذلك، لديك خيارات المعاملات المتاحة، مما يسمح لك بإرسال الأموال بسهولة، وإجراء الدفعات، والتحقق من سجل المعاملات، وإدارة حسابك، كل ذلك في مكان واحد.",
          },
          {
            img: "/images/Ewallet_list.png",
            title: "قائمة",
            details:
              "توجد شاشة ملائمة تعرض قوائم الوكلاء ومواقعهم. تتيح لك هذه الميزة تحديد موقع الوكلاء القريبين بسهولة لتلبية احتياجات الدفع الخاصة بك، مما يجعل المعاملات خالية من المتاعب ويمكن الوصول إليها.",
          },
          {
            img: "/images/Ewallet_history.png",
            title: "تاريخ",
            details:
              "توفر هذه الشاشة للمستخدمين قائمة شاملة بمعاملاتهم السابقة، مما يسمح لهم بتتبع أنشطة الدفع الخاصة بهم في مكان واحد. بالإضافة إلى ذلك، يمكن للمستخدمين عرض نظرة عامة مختصرة على معاملاتهم الأخيرة.",
          },
        ],
        technologiesUsedTitle: "التقنيات المستخدمة",
        technologies: [
          "Android",
          "java",
          "oauth - 2",
          "laravel",
          "php",
          "mvc",
          "swift",
          "ios",
          "uikit",
        ],
        availableOnTitle: "متاح على",
      },
    },
    card2: {
      title: "اتحاد دالكرد لكرة القدم",
      details:
        "لقد عملنا مع Dalkurd FF، وهو نادي كرة قدم سويدي يتنافس في بطولة Superettan الدوري ويمثل الروابط الثقافية داخل الشتات الكردي في بورلنج، دالارنا. النادي، الذي تأسس عام 2004، يرمز إلى الوحدة وقوة الرياضة.",
      info: {
        title: "اتحاد دالكرد لكرة القدم",
        subtitle: "منصة رياضية رقمية لعشاق كرة القدم",
        details:
          "لقد عملنا مع Dalkurd FF، وهو نادي كرة قدم سويدي يتنافس في بطولة Superettan الدوري ويمثل الروابط الثقافية داخل الشتات الكردي في بورلنج، دالارنا. النادي، الذي تأسس عام 2004، يرمز إلى الوحدة وقوة الرياضة.",
        featuresTitle: "سمات",
        features: [
          "استخدام تصميم التطبيقات المستجيبة",
          "دمج التكامل وسائل الاعلام الاجتماعية",
          "دمج ميزة البحث",
          "دمج سمات التعلم الآلي",
          "الامتثال لمعايير اللائحة العامة لحماية البيانات",
          "البساطة في التصميم مع عناصر مرتبة",
          "مسائل أمنية قوية",
          "تغطية واسعة النطاق عبر منصة",
          "استخدام الواقع المعزز في أنظمة متنوعة",
          "دمج الأحكام الخاصة بتعليقات المستخدم",
          "ميزات اللمس على شاشة الهاتف المحمول وافرة",
          "التكامل مع النظم البيئية الأقوى والأكثر قوة",
        ],
        problemStatementsTitle: "بيانات المشكلة:",
        problemStatements: [
          {
            textBold: "مشاركة محدودة للمعجبين:",
            text: "يفتقر تطبيق الهاتف المحمول الحالي إلى الميزات التفاعلية، مما يؤدي إلى الحد الأدنى من مشاركة المعجبين وتقليل الاحتفاظ بالمستخدمين.",
          },
          {
            textBold: "معلومات المباراة غير المكتملة:",
            text: "غالبًا ما يواجه المستخدمون صعوبة في الوصول إلى تحديثات المباراة الشاملة في الوقت الفعلي، بما في ذلك النتائج المباشرة وإحصائيات اللاعبين وتعليقات المباراة.",
          },
          {
            textBold: "تعقيد التنقل:",
            text: "يجد المستخدمون صعوبة في التنقل في التطبيق، مما يؤدي إلى تجربة مستخدم مفككة وصعوبة في الوصول إلى المعلومات المطلوبة.",
          },
          {
            textBold: "نظام التذاكر غير الملائم:",
            text: "يفتقر نظام التذاكر الحالي إلى سهولة الاستخدام ويفشل في توفير تجربة شراء تذاكر سلسة للجماهير.",
          },
          {
            textBold: "التخصيص المحدود:",
            text: "لا يستطيع المستخدمون تخصيص تجربة التطبيق الخاصة بهم وفقًا لتفضيلاتهم، مما يقلل من رضا المستخدم بشكل عام.",
          },
        ],
        solutionsTitle: "حلول:",
        solutions: [
          {
            textBold: "تعزيز مشاركة المعجبين:",
            text: "قم بتطوير ميزات تفاعلية مثل استطلاعات الرأي والاختبارات ومنتديات المعجبين لتعزيز المشاركة وخلق شعور بالمجتمع بين المعجبين.",
          },
          {
            textBold: "تحديثات المباريات في الوقت الفعلي:",
            text: "قم بتنفيذ أداة تعقب قوية للمباريات المباشرة لتزويد المستخدمين بنتائج محدثة وإحصائيات اللاعبين وتعليقات المباراة للحصول على تجربة غامرة.",
          },
          {
            textBold: "التنقل المبسط:",
            text: "أعد تصميم واجهة مستخدم التطبيق باستخدام مسارات التنقل البديهية، مما يضمن سهولة الوصول إلى الأخبار والجداول الزمنية وملفات تعريف اللاعبين.",
          },
          {
            textBold: "نظام التذاكر المحسّن:",
            text: "قم بتجديد نظام التذاكر من خلال واجهة سهلة الاستخدام وخيارات دفع آمنة وعمليات شراء التذاكر المبسطة.",
          },
          {
            textBold: "خيارات التخصيص:",
            text: "تقديم ميزات التخصيص، مما يسمح للمستخدمين بتخصيص تفضيلات المحتوى والإشعارات وموضوعات التطبيق للحصول على تجربة مخصصة.",
          },
        ],
        productScreensTitle: "شاشات المنتج:",
        productScreens: [
          {
            img: "/images/Dalkurd_HomePage.png",
            title: "الصفحة الرئيسية",
            details:
              "مرحبًا بك في قلب تطبيق Dalkurd للهاتف المحمول، بوابتك إلى آخر تحديثات المباريات وأخبار الفريق وتفاعلات المعجبين المثيرة، كل ذلك في مكان واحد.",
          },
          {
            img: "/images/Dalkurd_News.png",
            title: "أخبار",
            details:
              "انغمس في عالم Dalkurd من خلال تجربة الوسائط المتعددة لدينا، حيث نقدم مقاطع فيديو حصرية وأبرز اللقطات ولمحات من وراء الكواليس في رحلة الفريق.",
          },
          {
            img: "/images/Dalkurd_TV.png",
            title: "تلفزيون",
            details:
              "اكتشف وتقدير الشركاء الكرام الذين يدعمون رحلة Dalkurd، من خلال قسم مخصص يعرض الرعاة الكرام ومساهماتهم.",
          },
          {
            img: "/images/Dalkurd_Membership.png",
            title: "عضوية",
            details:
              "انضم إلى عائلة Dalkurd من خلال أن تصبح عضوًا، وفي حالة وجود أي استفسارات أو مساعدة، فإن دعم العملاء لدينا هو مجرد نقرة واحدة، مما يضمن تجربة تطبيق سلسة وممتعة.",
          },
          {
            img: "/images/Dalkurd_Support.png",
            title: "يدعم",
            details:
              "انضم إلى عائلة Dalkurd من خلال أن تصبح عضوًا، وفي حالة وجود أي استفسارات أو مساعدة، فإن دعم العملاء لدينا هو مجرد نقرة واحدة، مما يضمن تجربة تطبيق سلسة وممتعة.",
          },
        ],
        technologiesUsedTitle: "التقنيات المستخدمة",
        technologies: [
          "Android",
          "java",
          "oauth - 2",
          "laravel",
          "php",
          "mvc",
          "swift",
          "ios",
          "uikit",
        ],
        availableOnTitle: "متاح على",
      },
    },
    card3: {
      title: "الاتصال السريع (نقاط البيع)",
      details:
        "من خلال برنامج نقاط البيع الخاص بنا، يمكن للشركات تبسيط عملياتها. سمات مثل مراجعة سجل المعاملات، وإعادة طباعة إيصال الدفع، ودفع QR Scan توفر القدرات تجارب سلسة للعملاء.",
      info: {
        title: "الاتصال السريع (نقاط البيع)",
        subtitle: "الإلكترونية المتقدمة اعرف عميلك",
        details:
          "من خلال برنامج نقاط البيع الخاص بنا، يمكن للشركات تبسيط عملياتها. سمات مثل مراجعة سجل المعاملات، وإعادة طباعة إيصال الدفع، ودفع QR Scan توفر القدرات تجارب سلسة للعملاء.",
        featuresTitle: "Features",
        features: [
          "إعادة طباعة إيصال الدفع",
          "دفع مسح QR",
          "واجهة مستخدم بديهية",
          "إيصالات قابلة للتخصيص",
          "تحليلات المبيعات",
          "ادارة المخزون",
          "مراجعة تاريخ المعاملات",
          "أدوار المستخدم والأذونات",
          "خيارات دفع متعددة",
        ],
        problemStatementsTitle: "بيانات المشكلة:",
        problemStatements: [
          {
            textBold: "عدم الكفاءة التشغيلية:",
            text: "تواجه الشركات تحديات في إدارة عملياتها بفعالية، مما يؤدي إلى التأخير والأخطاء واستياء العملاء.",
          },
          {
            textBold: "الافتقار إلى شفافية المعاملات:",
            text: "تكافح الشركات لتزويد العملاء بسجل معاملات شفاف وسهولة الوصول إلى إيصالات الدفع.",
          },
          {
            textBold: "عمليات الدفع المعقدة:",
            text: "تعيق إجراءات الدفع المعقدة تجارب العملاء السلسة وقد تمنع المبيعات المحتملة.",
          },
        ],
        solutionsTitle: "حلول:",
        solutions: [
          {
            textBold: "العمليات المبسطة:",
            text: 'يمكّن "الاتصال السريع" الشركات من تبسيط عملياتها من خلال توفير أدوات بديهية لإدارة المخزون وتتبع المبيعات وإعداد التقارير.',
          },
          {
            textBold: "خيارات دفع سلسة:",
            text: "دمج إمكانيات الدفع QR Scan لتزويد العملاء بطريقة دفع مريحة وسريعة، مما يعزز تجربة التسوق الشاملة الخاصة بهم.",
          },
          {
            textBold: "إدارة الإيصالات:",
            text: "توفير القدرة على إعادة طباعة إيصالات الدفع بسهولة، وحل استفسارات العملاء وضمان المساءلة.",
          },
          {
            textBold: "التوافق مع الأنظمة الأساسية المتعددة:",
            text: "ضمان التوافق عبر الأجهزة والأنظمة الأساسية المختلفة، مما يمكّن الشركات من تلبية تفضيلات العملاء المختلفة.",
          },
          {
            textBold: "أمن البيانات:",
            text: "تنفيذ تدابير أمنية قوية لحماية بيانات العملاء ومعلومات الدفع وتعزيز الثقة والامتثال.",
          },
        ],
        productScreensTitle: "شاشات المنتج:",
        productScreens: [
          {
            img: "/images/Dalkurd_HomePage.png",
            title: "الصفحة الرئيسية",
            details:
              "توفر الصفحة الرئيسية، المحور المركزي لـ Fast Connect POS، نظرة عامة سريعة على المبيعات والمخزون والرؤى اليومية، مما يمكّن الشركات من اتخاذ قرارات مستنيرة.",
          },
          {
            img: "/images/Dalkurd_News.png",
            title: "مسح QR",
            details:
              "أحدث ثورة في معاملات العملاء باستخدام ميزة مسح QR للدفع، مما يسمح بإجراء عمليات دفع سلسة وبدون تلامس من خلال المسح السريع لرمز QR، مما يعزز الراحة والأمان.",
          },
          {
            img: "/images/Dalkurd_TV.png",
            title: "تاريخ الدفع",
            details:
              "يمكنك الوصول بسهولة إلى سجل شامل لجميع المعاملات التي تمت معالجتها ومراجعته، مما يوفر للشركات لمحة سريعة عن أنشطتها المالية في الوقت الفعلي.",
          },
          {
            img: "/images/Dalkurd_Membership.png",
            title: "ملخص المعاملات",
            details:
              "تعمق في سجلات المعاملات التفصيلية لتحليل اتجاهات الشراء وساعات الذروة وتفضيلات العملاء، مما يساعد الشركات على تحسين عروضها واستراتيجياتها.",
          },
          {
            img: "/images/Dalkurd_Support.png",
            title: "إشعار",
            details:
              "ابق على اطلاع دائم باستخدام نظام إشعارات قوي يبقي الشركات على علم بمستويات المخزون المنخفضة والطلبات الجديدة والتحديثات المهمة، مما يضمن الاستجابات في الوقت المناسب والعمليات السلسة.",
          },
        ],
        technologiesUsedTitle: "التقنيات المستخدمة",
        technologies: ["kotlin", "mvp", "android"],
        availableOnTitle: "متاح على",
      },
    },
    card4: {
      title: "حل KYC الإلكتروني",
      details:
        "يعمل حل e-KYC المتقدم الخاص بنا على تبسيط عملية التحقق من العملاء بأحدث التقنيات تكنولوجيا. فهو يوفر التحقق من الهوية بشكل آمن وعن بعد وفعال، مما يضمن الامتثال مع تعزيز عملية تأهيل العملاء.",
      info: {
        title: "حل KYC الإلكتروني",
        subtitle: "الإلكترونية المتقدمة اعرف عميلك",
        details:
          "يعمل حل e-KYC المتقدم الخاص بنا على تبسيط عملية التحقق من العملاء بأحدث التقنيات تكنولوجيا. فهو يوفر التحقق من الهوية بشكل آمن وعن بعد وفعال، مما يضمن الامتثال مع تعزيز عملية تأهيل العملاء.",
        featuresTitle: "سمات",
        features: [
          "التحقق من صحة الوقت الحقيقي",
          "التحقق من العنوان",
          "مسح المستندات ضوئيًا",
          "سير العمل قابل للتخصيص",
          "تشفير البيانات الآمنة",
          "توقيع إلكتروني",
          "تكامل دعم العملاء",
          "تقارير الامتثال",
          "لوحة تحكم المستخدم",
        ],
        problemStatementsTitle: "بيانات المشكلة:",
        problemStatements: [
          {
            textBold: "اختناقات التحقق اليدوي:",
            text: " تؤدي عمليات التحقق اليدوي التقليدية إلى اختناقات وتأخير وإحباط لكل من العملاء والشركات أثناء عملية الإعداد.",
          },
          {
            textBold: "المخاوف الأمنية:",
            text: "يؤدي عدم وجود طريقة تحقق آمنة وموحدة إلى تعريض بيانات العملاء الحساسة لانتهاكات محتملة ومخاطر الاحتيال.",
          },
          {
            textBold: "تجربة مستخدم غير متسقة:",
            text: "غالبًا ما يواجه العملاء رحلة مستخدم مفككة ومربكة أثناء محاولتهم التحقق من هويتهم، مما يؤثر على رضا المستخدم.",
          },
          {
            textBold: "تحديات الامتثال:",
            text: "يفرض تلبية المتطلبات التنظيمية المتطورة للتحقق من الهوية تحديات، مما يؤدي إلى مشكلات قانونية وتشغيلية محتملة.",
          },
          {
            textBold: "تعقيد التحقق عن بعد:",
            text: "يجد العملاء عن بعد صعوبة في التحقق من هويتهم، مما يؤدي إلى حالات الانقطاع وعدم اكتمال عمليات الإعداد.",
          },
        ],
        solutionsTitle: "حلول:",
        solutions: [
          {
            textBold: "عملية التحقق الآلية:",
            text: "تنفيذ حل تلقائي للتعرف على عميلك الإلكتروني (KYC) يقلل من التدخل اليدوي، ويسرع عملية الإعداد ويقلل من الاختناقات.",
          },
          {
            textBold: "تدابير أمنية متقدمة:",
            text: "دمج التشفير القوي والمصادقة البيومترية واكتشاف الاحتيال القائم على الذكاء الاصطناعي لضمان عملية التحقق من الهوية آمنة للغاية.",
          },
          {
            textBold: "تجربة مستخدم سلسة:",
            text: "صمم واجهة بديهية وسهلة الاستخدام مع تعليمات واضحة وإشارات مرئية لتوجيه العملاء خلال رحلة تحقق سلسة.",
          },
          {
            textBold: "إطار الامتثال التنظيمي:",
            text: "تطوير إطار امتثال شامل يتكيف مع اللوائح المتغيرة، ويضمن الالتزام القانوني ومنع الاضطرابات التشغيلية.",
          },
          {
            textBold: "واجهة سهلة الاستخدام عن بعد:",
            text: "قم بتحسين تطبيق الهاتف المحمول للتحقق من الهوية عن بعد، وتقديم إرشادات ودعم خطوة بخطوة للعملاء، بغض النظر عن موقعهم.",
          },
        ],
        productScreensTitle: "شاشات المنتج:",
        productScreens: [
          {
            img: "/images/KYC_ScanID.png",
            title: "معرف المسح",
            details:
              "يمكنك التقاط هويتك والتحقق من صحتها بسلاسة عن طريق مسح مستند الهوية الرسمي الخاص بك ضوئيًا باستخدام تطبيق الهاتف المحمول e-KYC، مما يضمن التحقق الآمن والفعال.",
          },
          {
            img: "/images/KYC_UploadAddress.png",
            title: "تحميل العنوان",
            details:
              "قم بتبسيط عملية التحقق بشكل أكبر عن طريق تحميل بطاقة العنوان الخاصة بك، مما يسمح لنا بالتحقق من تفاصيل إقامتك بسرعة ودقة.",
          },
          {
            img: "/images/KYC_FaceAuthentication.png",
            title: "مصادقة الوجه",
            details:
              "استمتع بتجربة الأمان المتطورة من خلال مصادقة الوجه، حيث توفر ميزات وجهك الفريدة طريقة آمنة ومريحة للتحقق من الهوية.",
          },
          {
            img: "/images/KYC_Signature.png",
            title: "إمضاء",
            details:
              "أكمل عملية التحقق باستخدام التوقيع الرقمي، مما يضيف طبقة إضافية من الأصالة ويضمن تجربة اعرف عميلك الإلكترونية الشاملة والآمنة.",
          },
        ],
        technologiesUsedTitle: "التقنيات المستخدمة",
        technologies: [
          "Java",
          "google vision",
          "google mlkit",
          "android",
          "swift",
          "uikit",
          "ocr",
        ],
        availableOnTitle: "متاح على",
      },
    },
    card5: {
      title: "العملات المشفرة – IQDX",
      details:
        "IQD-X، وهي شركة رائدة في مجال تبادل العملات المشفرة، ومقرها في العراق، توفر سهولة الوصول إلى العالم الديناميكي للأصول الرقمية. تم تصميم IQDX لدمج أفضل الميزات لقادة الصناعة مثل Binance وOKX وBybit، ويوفر تجربة تداول استثنائية مليئة بالابتكارات الفريدة.",
      info: {
        title: "العملات المشفرة - IQDX",
        subtitle: "الإلكترونية المتقدمة اعرف عميلك",
        details:
          "تقدم IQDX للمستخدمين مجموعة واسعة من الإمكانات للحصول على تجربة تداول قوية للعملات المشفرة. من خلال الاستفادة من تقنية blockchain، فإنها تتيح التداول بسهولة، والأدوات المتقدمة، والتخزين الآمن، والتنفيذ السريع للأوامر، والموارد التعليمية، والمشاركة المجتمعية، والدعم سريع الاستجابة، والميزات المبتكرة. بفضل التصميم الذي يركز على المستخدم، تعمل IQDX على تمكين المتداولين من التنقل بثقة في عالم الأصول الرقمية الديناميكي.",
        featuresTitle: "Features",
        features: [
          "التحقق من الدقة العالية",
          "المعالجة في الوقت الحقيقي",
          "واجهة سهلة الاستخدام",
          "قابلية التوسع",
          "التوافق عبر الأنظمة الأساسية",
          "المصادقة بدون تلامس",
        ],
        problemStatementsTitle: "بيانات المشكلة:",
        problemStatements: [
          {
            textBold: "قابلية التوسع والأداء:",
            text: "ضمان التعامل السلس مع المعاملات والبيانات في الوقت الفعلي لقاعدة مستخدمين متنامية مع الحفاظ على زمن الوصول المنخفض.",
          },
          {
            textBold: "الأمن وخصوصية البيانات:",
            text: "حماية بيانات المستخدم ومعاملاته ضد التهديدات السيبرانية مع الالتزام بأنظمة الخصوصية.",
          },
          {
            textBold: "التدقيق المطلوب:",
            text: " التنقل في لوائح العملة المشفرة العالمية لضمان الامتثال القانوني عبر الولايات القضائية.",
          },
          {
            textBold: "تجربة المستخدم والواجهة:",
            text: "تحقيق التوازن بين التصميم سهل الاستخدام والميزات المتقدمة لمجموعة متنوعة من المتداولين.",
          },
          {
            textBold: "تقلبات السوق ودقة البيانات:",
            text: "توفير بيانات دقيقة عن السوق في الوقت الفعلي على الرغم من الطبيعة المتقلبة لسوق العملات المشفرة.",
          },
        ],
        solutionsTitle: "حلول:",
        solutions: [
          {
            textBold: "1. قابلية التوسع والأداء:",
            text: [
              "تنفيذ بنية موزعة للتعامل مع حجم المعاملات المتزايد وتوزيع الحمل.",
              "استخدم الخدمات السحابية وبنية الخدمات الصغيرة لتوسيع نطاق الموارد ديناميكيًا.",
              "تحسين استعلامات قاعدة البيانات واستخدام آليات التخزين المؤقت لاسترجاع البيانات بشكل أسرع.",
              "إجراء اختبارات أداء منتظمة وتحسينها لتحديد الاختناقات ومعالجتها.",
            ],
          },
          {
            textBold: "2. الأمن وخصوصية البيانات:",
            text: [
              "تنفيذ آليات تشفير قوية لتأمين بيانات المستخدم والمعلومات الحساسة.",
              "استخدم المصادقة متعددة العوامل (MFA) لتعزيز أمان حساب المستخدم.",
              "قم بتحديث مكونات البرامج وتصحيحها بانتظام لمنع الثغرات الأمنية.",
              "إجراء عمليات تدقيق أمنية شاملة واختبار الاختراق لتحديد نقاط الضعف المحتملة ومعالجتها.",
            ],
          },
          {
            textBold: "3. التدقيق المطلوب:",
            text: [
              "إنشاء فريق قانوني لرصد وضمان الامتثال للوائح العملة المشفرة المتطورة.",
              "سياج جغرافي لبعض الميزات أو العملات المشفرة بناءً على ولايات قضائية محددة للامتثال للقوانين الإقليمية.",
              "الشراكة مع الخبراء القانونيين لتفسير اللوائح الجديدة والتكيف معها عند ظهورها.",
            ],
          },
          {
            textBold: "4. تجربة المستخدم والواجهة:",
            text: [
              "قم بإجراء بحث المستخدم لفهم احتياجات وتفضيلات شرائح المستخدمين المختلفة.",
              "تقديم واجهات قابلة للتخصيص، مما يسمح للمستخدمين بتخصيص النظام الأساسي حسب تفضيلاتهم.",
              "توفير برامج تعليمية شاملة وموارد تعليمية لدعم المتداولين على جميع المستويات.",
              "جمع تعليقات المستخدمين بانتظام وتكرار تصميم النظام الأساسي بناءً على اقتراحات المستخدمين.",
            ],
          },
          {
            textBold: "5. تقلبات السوق ودقة البيانات:",
            text: [
              "تعاون مع موفري بيانات السوق الموثوقين لضمان خلاصات بيانات دقيقة وفي الوقت الفعلي.",
              "تنفيذ آليات للتعامل مع ارتفاع البيانات وعرضها أثناء التقلبات العالية",
              "تقديم البيانات التاريخية وأدوات التحليل لمساعدة المتداولين على اتخاذ قرارات مستنيرة.",
              "تنفيذ قواطع الدائرة والضمانات لمنع الصفقات الخاطئة أثناء تقلبات السوق الشديدة.",
            ],
          },
        ],
        productScreensTitle: "شاشات المنتج:",
        productScreens: [
          {
            img: "/images/Dashboard.png",
            title: "لوحة القيادة",
            details:
              "Cتعد لوحة المعلومات هي المحور المركزي لـ IQDX، حيث توفر للمستخدمين نظرة عامة سريعة على محفظة العملات المشفرة الخاصة بهم. ويعرض بيانات السوق في الوقت الحقيقي، وأرصدة الحسابات، والمعاملات الأخيرة، والإعلانات الهامة.",
          },
          {
            img: "/images/Trade.png",
            title: "تجارة",
            details:
              "شاشة التداول هي المكان الذي يمكن للمستخدمين من خلاله تنفيذ عمليات تداول العملات المشفرة بسهولة. وهو يوفر أدوات تداول متقدمة، بما في ذلك مخططات التحليل الفني وبيانات دفتر الطلبات وأزواج التداول.",
          },
          {
            img: "/images/Verfication.png",
            title: "التحقق",
            details:
              "يعد التحقق من الهوية عنصرًا حاسمًا في IQDX، مما يضمن بيئة تداول آمنة ومتوافقة.",
          },
          {
            img: "/images/Buy_Currency.png",
            title: "شراء العملة",
            details:
              "تتيح هذه الميزة للمستخدمين شراء العملات المشفرة مباشرة من محافظهم المتصلة. إنه يبسط عملية إضافة الأصول الرقمية إلى محفظة التداول.",
          },
          {
            img: "/images/Sell_Crypto.png",
            title: "بيع التشفير",
            details:
              "فهو يمكّن المستخدمين من بيع أصولهم الرقمية بسهولة على المنصة. يمكن للمتداولين اختيار العملة المشفرة التي يريدون بيعها، وتحديد المبلغ أو السعر، وتنفيذ أوامر البيع بسرعة.",
          },
        ],
        technologiesUsedTitle: "التقنيات المستخدمة",
        technologies: [
          "kotlin",
          "android",
          "mvvm",
          "trading view",
          "swift ui",
          "swift",
        ],
        availableOnTitle: "متاح على",
      },
    },
    card6: {
      title: "تمييز الوجوه",
      details:
        "لقد أمضى فريق الذكاء الاصطناعي لدينا سنوات في التخصص في برامج التعرف على الوجوه المخصصة تطوير. نحن نقدم خدماتنا بدعم من الأبحاث المكثفة والمحللين ذوي الخبرة حلول الدورة الكاملة المصممة خصيصًا والتي تتوافق تمامًا مع احتياجات عملك.",
      info: {
        title: "تمييز الوجوه",
        subtitle: "الإلكترونية المتقدمة اعرف عميلك",
        details:
          "لقد أمضى فريق الذكاء الاصطناعي لدينا سنوات في التخصص في برامج التعرف على الوجوه المخصصة تطوير. نحن نقدم خدماتنا بدعم من الأبحاث المكثفة والمحللين ذوي الخبرة حلول الدورة الكاملة المصممة خصيصًا والتي تتوافق تمامًا مع احتياجات عملك.",
        featuresTitle: "سمات",
        features: [
          "واجهة سهلة الاستخدام",
          "المعالجة في الوقت الحقيقي",
          "التحقق من الدقة العالية",
          "المصادقة بدون تلامس",
          "التوافق عبر الأنظمة الأساسية",
          "قابلية التوسع",
        ],
        problemStatementsTitle: "بيانات المشكلة:",
        problemStatements: [
          {
            textBold: "عمليات التحقق غير الدقيقة:",
            text: "غالبًا ما تظهر أنظمة التعرف على الوجوه الحالية معلومات غير دقيقة، مما يؤدي إلى نتائج إيجابية أو سلبية كاذبة أثناء التحقق من الهوية.",
          },
          {
            textBold: "قيود الأجهزة:",
            text: "قد تتطلب بعض حلول التعرف على الوجوه أجهزة متخصصة، مما يحد من سهولة استخدامها وإمكانية الوصول إليها عبر الأجهزة المختلفة.",
          },
          {
            textBold: "مخاوف الخصوصية:",
            text: "قد يكون لدى المستخدمين تحفظات بشأن تخزين بياناتهم البيومترية واستخدامها للتحقق، مما يثير مخاوف بشأن الخصوصية وحماية البيانات.",
          },
        ],
        solutionsTitle: "حلول:",
        solutions: [
          {
            textBold: "الخوارزميات المتقدمة:",
            text: "تستخدم خوارزميات التعرف على الوجه المتطورة التي تخضع للتحسين المستمر والتدريب لتعزيز الدقة.",
          },
          {
            textBold: "التكامل مع الأجهزة المحمولة:",
            text: "تطوير حلول للتعرف على الوجوه متوافقة مع الأجهزة المحمولة والتي تستفيد من كاميرات الأجهزة المدمجة، مما يجعلها في متناول قاعدة",
          },
          {
            textBold: "التحديثات المنتظمة:",
            text: "توفير تحديثات منتظمة للبرامج لتحسين الأداء والأمان والتكيف مع التهديدات المتطورة.",
          },
          {
            textBold: "المراقبة المستمرة:",
            text: "تنفيذ المراقبة والتنبيهات في الوقت الفعلي للكشف عن أي حالات شاذة أو أنشطة مشبوهة داخل نظام التعرف على الوجوه.",
          },
        ],
        productScreensTitle: "شاشات المنتج:",
        productScreens: [
          {
            img: "/images/FaceRecognition_AccurateIdentityVerification.png",
            title: "التحقق الدقيق من الهوية",
            details:
              "يستخدم نظامنا المتقدم للتعرف على الوجوه خوارزميات متطورة لتقديم عملية تحقق دقيقة وموثوقة من الهوية، مما يضمن الدقة في كل عملية مصادقة. استمتع بتجربة التحقق بدون لمس مع تقنية التعرف على الوجه، مما يسمح للمستخدمين بالتحقق من هويتهم بسهولة وأمان.",
          },
        ],
        technologiesUsedTitle: "التقنيات المستخدمة",
        technologies: [
          "Java",
          "google vision",
          "google mlkit",
          "android",
          "swift",
          "uikit",
        ],
        availableOnTitle: "متاح على",
      },
    },
  },
  homeAccreditation: {
    title1: "الاعتماد",
    title2: "والعضوية",
    subtitle: "جمعيات معروفة",
    details: "متطلبات العملاء والمتطلبات باستمرار.",
    card1: {
      details: "متطلبات العملاء والمتطلبات باستمرار.",
    },
    card2: {
      details:
        "عضو في الرابطة الوطنية لشركات وتكنولوجيا المعلومات والاتصالات في بنغلاديش.",
    },
    card3: {
      details:
        "متطلبات التشغيل الآمن أمن المعلومات (ISMS) وصيانته وتحسينه لحماية المعلومات الأساسية.",
    },
  },
  homeCaseStudies: {
    title: "دراسات الحالة",
    subtitle: "إِبداع. المرونة. ابتكار",
    card1: {
      title: "خدمات التحقق من E-KYC",
      details: "التحقق من صحة معلومات العميل والتحقق منها",
    },
    card2: {
      title: "خدمات التحقق من E-KYC",
      details: "التحقق من صحة معلومات العميل والتحقق منها",
    },
    card3: {
      title: "خدمات التحقق من E-KYC",
      details: "التحقق من صحة معلومات العميل والتحقق منها",
    },
    card4: {
      title: "خدمات التحقق من E-KYC",
      details: "التحقق من صحة معلومات العميل والتحقق منها",
    },
  },
  homeContact: {
    title: "غير طريقة تفكيرك. نجعلها ممكنة!",
    details:
      "هل تجلس على فكرة رائعة / رائعة؟ ، فريق NewrozTech، Sho نتعامل معه ونحوله إلى حقيقة تشكل حلمك!",
    contactTitle: "اتصال",
    email: "contact@newroztech.com",
    usNumber: "US: +1 (202) 555-0156",
    bdNumber: "+88 014 0119 9557",
    iqNumber: "+964 750 939 3344",
    iqNumber2: "+964 751 840 3143",
    uaeNumber: "+971 56 626 6825",
    workingHourTitle: "ساعة العمل",
    workingHour: "الأحد - الخميس: 10 صباحًا - 7 مساءً",
    formTextYourName: "اسمك",
    formTextEmail: "بريد إلكتروني",
    formTextPhone: "هاتف",
    formTextYourMassage: "التدليك الخاص بك",
  },
  // ---------------------company section start------------------
  companyTopSection: {
    title:
      "تتمتع NewrozTech بربطك أنت وأعمالك بالقوة الهائلة المحتملة للتكنولوجيا.",
    details:
      "في NewrozTech ، نحن متخصصون في سد الفجوة بين المتطلبات التنظيمية والقدرات الهائلة للتكنولوجيا. تكمن خبرتنا في قبول التحديات وجهاً لوجه ، وصياغة أفكارك ببراعة في إطار عمل قوي للتحول الرقمي. شغفنا هو الاستفادة من الإمكانات اللانهائية للتكنولوجيا لتحويل مفاهيمك الرؤية إلى واقع يتحول رقميًا.",
  },
  companyWhoYouAreSection: {
    title: "من نحن",
    subtitle:
      "نحن ، فريق Newroz Technologies نحب العمل معًا بشكل جيد لتحقيق رؤية وأهداف مشتركة.",
    details1:
      "Newroz Technologies ، التي تأسست في عام 2020 في دكا ، بنغلاديش ، مكرسة لتحويل الأفكار الرائعة إلى منتجات تكنولوجية ناجحة. لسد الفجوة بين الاحتياجات التنظيمية وإمكانات التكنولوجيا ، فإنهم يتفوقون في التحول الرقمي. نظرًا لالتزامهم بتجارب العملاء الاستثنائية ، فإنهم يقدرون ملاحظات العملاء كفرصة للنمو والتحسين.",
    details2:
      "بفضل النزاهة التي لا تتزعزع والخدمات المصممة خصيصًا ، حصلوا على توصيات شفهية واعتراف عالمي. تشمل خبراتهم استشارات تكنولوجيا المعلومات والتنفيذ وتطوير التطبيقات المستندة إلى الويب والبرامج المخصصة وتطبيقات الأجهزة المحمولة والاستشارات السحابية وحلول الذكاء الاصطناعي. إنهم مدفوعون بشغف للابتكار وتصميم مواقع الويب الديناميكي.",
  },
  companyWhatWeBelieve: {
    title: "ما نؤمن به",
    subtitle:
      "نحن ، فريق Newroz Technologies نحب العمل معًا بشكل جيد لتحقيق رؤية وأهداف مشتركة.",
    details:
      "يعزز الترابط الجماعي بين موظفينا إمكانات فريقنا وهو جمال وقوة نجاحنا التدريجي. بدأنا رحلتنا مع 10 أشخاص فقط ، واليوم أصبحنا أكثر من 70 موظفًا",
    card1: {
      title: "جودة",
      details:
        "نسعى جاهدين لإسعاد عملائنا بأفضل الخدمات عالية الجودة لأننا نؤمن بأن استدامتنا المستقبلية تكمن في أيدي عملائنا الكرام.",
    },
    card2: {
      title: "التزام العميل",
      details:
        "نحن نعلم أن بناء الثقة يستغرق سنوات وثواني فقط لتدميرها. وبالتالي ، نحن نهتم باحتياجات عملائنا لتقديم أفضل الحلول من خلال التحسين المستمر لخدماتنا.",
    },
    card3: {
      title: "نزاهة",
      details:
        "الالتزام بدون نزاهة يساوي الصفر. نحن نفضل تقديم خدمات مصممة خصيصًا ومخصصة للصناعة في متناول يدك ، ووسائلك وخارجه ، وهو أمر يمثل تحديًا ولكنه قابل للتنفيذ.",
    },
    card4: {
      title: "العمل بروح الفريق الواحد",
      details:
        "نحن فريق Newroztech نحب العمل معًا بشكل جيد نحو رؤية وأهداف مشتركة. يعزز الترابط الجماعي بين موظفينا إمكانات فريقنا وهو جمال وقوة نجاحنا التدريجي.",
    },
  },
  companyCEOSection: {
    title: "سركات أ جوناد",
    subtitle: "رئيس مجلس إدارة شركة Newroz Technologies LTD",
    details1:
      "إنه لمن دواعي سروري البالغ إعلان دخول شركة Newroz Technologies Limited في المجال الديناميكي للتكنولوجيا المالية. مسترشدين بسعينا الدؤوب للابتكار و التميز ، نحن على استعداد لإحداث ثورة في الخدمات المالية ، وتعزيز جهد وبيئة آمنة للجميع.",
    details2:
      "دعمكم الثابت ، إلى جانب اجتهاد فريقنا الاستثنائي ، يدفعنا نحو مستقبل غني بالإمكانيات ، حيث يمكننا إحداث تأثير هادف على Fintech Landscape. لسنا متحمسين للمستقبل فقط ، بل نتوق إلى تشكيله ، معاً.",
    details3:
      "نشكرك على الانطلاق في هذه الرحلة التحويلية معنا. دعنا نواصل تحدي القاعدة وإعادة تعريف عالم الاحتمالات.",
  },
  compantBrainBehindNewrozSection: {
    title: "دماغ خلف نوروز",
    details: "نحن ، فريق Newroz Technologies نحب العمل بشكل جيد",
  },
  compantRoadmapSection: {
    title: "خريطة الطريق",
    subtitle: "خريطة رحلة نوروز كاملة",
    card1: {
      year: "2017",
      title: "النمو الأولي والخبرة",
      details:
        "مع التركيز على تجميع فريق ماهر من المطورين والمتخصصين في الصناعة ، نحن قطع شوطا طويلا في إتقان التقنيات الرئيسية وتوجيه اتجاهات الصناعة. في وقت مبكر تميزت الأيام بالتنفيذ الناجح للمشاريع الصغيرة والمتوسطة الحجم ، مما أدى إلى صقل خبرتنا وخبراتنا.",
    },
    card2: {
      year: "2019",
      title: "توسع والتنويع",
      details:
        "كما شهدنا نموًا كبيرًا ، وسعت نوروز قاعدة عملائها وصنعت في مجالات متنوعة مثل التجارة الإلكترونية والتمويل والرعاية الصحية. عززت هذه المرحلة من رحلتنا سمعتنا في صياغة حلول مبتكرة وموثوقة.",
    },
    card3: {
      year: "2022",
      title: "التركيز على الحلول التي تركز على العملاء",
      details:
        "تحول تركيزنا نحو تعزيز العلاقات مع العملاء واكتساب فهم أعمق لاحتياجاتهم المتطورة. من خلال اعتماد منهجيات Agile ، نحن انسيابية تسليم مشروعنا. بالإضافة إلى ذلك ، ضاعفنا من التخصيص حلول لتلبية المتطلبات الفريدة لكل عميل.",
    },
    card4: {
      year: "2023",
      title: "تسلق مرتفعات جديدة",
      details:
        "استعدادًا للتعامل مع مشاريع أكبر وأكثر تعقيدًا ، قمنا بتوسيع نطاق فريقنا و بنية تحتية. قمنا أيضًا بزيادة استثماراتنا في البحث والتطوير إلى  رأس الحربة الحلول المتطورة. نتيجة لذلك ، أثبت نوروز نفسه كمؤسسة موثوق بها شريك تقني للشركات في جميع أنحاء العالم.",
    },
  },
  companyCustomersSpeakForUsSection: {
    title: "Customers Speak For Us",
    subtitle:
      "We, Newroz Technologies team like to work well together toward a common vision and goals.",
    card1: {
      name: "Md Isfat Sharik",
      designation: "CEO of VIT",
      details:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam dictum aliquet accumsan porta lectus ridiculus in mattis. Netus sodales in pat ullamcorper amet adipiscing fermentum.aliquet accumsan porta lectus ridiculus in mattis",
    },
    card2: {
      name: "Md Isfat Sharik",
      designation: "CEO of VIT",
      details:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam dictum aliquet accumsan porta lectus ridiculus in mattis. Netus sodales in pat ullamcorper amet adipiscing fermentum.aliquet accumsan porta lectus ridiculus in mattis",
    },
    card3: {
      name: "Md Isfat Sharik",
      designation: "CEO of VIT",
      details:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam dictum aliquet accumsan porta lectus ridiculus in mattis. Netus sodales in pat ullamcorper amet adipiscing fermentum.aliquet accumsan porta lectus ridiculus in mattis",
    },
    card4: {
      name: "Md Isfat Sharik",
      designation: "CEO of VIT",
      details:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam dictum aliquet accumsan porta lectus ridiculus in mattis. Netus sodales in pat ullamcorper amet adipiscing fermentum.aliquet accumsan porta lectus ridiculus in mattis",
    },
  },
  companyOurMissionAndOurVisionSection: {
    title: "مهمتنا ورؤيتنا",
    subtitle: "تمكين الشركات باستخدام أحدث التقنيات لتحقيق النمو التحويلي",
    card1: {
      title: "مهمة",
      details:
        "مهمتنا هي العمل كمحفز للشركات ، ومساعدتها في رفع القوة التكنولوجيا لتسريع رحلة التحول الرقمي. نحن نهدف إلى تشكيل الأحلام إلى واقع ، وتحويل الأفكار ذات الرؤية إلى قصص نجاح تكنولوجي.",
    },
    card2: {
      title: "رؤية",
      details:
        "تتمثل رؤيتنا في الريادة في مستقبل تحول الأعمال ، والدفع المستمر نحو المستقبل حدود ما هو ممكن مع التكنولوجيا. نحن نتخيل عالما حيث الأعمال يتم تمكينها من خلال الابتكار الرقمي ، مما يؤدي إلى تسريع نموها وقيادة عميقة تأثير على نطاق عالمي.",
    },
  },
  companyAtAGlanceSection: {
    title: "في لمحة",
    subtitle: "حقق النجاح طوال الرحلة",
    card1: {
      number: "70+",
      title: "أعضاء الفريق",
    },
    card2: {
      number: "10+",
      title: "المشاريع المنجزة",
    },
    card3: {
      number: "6+",
      title: "خدم البلدان",
    },
    card4: {
      number: "8+",
      title: "عملاء",
    },
  },
  companyTechnologiesWeUse: {
    title: "التقنيات التي نستخدمها",
    subtitle: "نحن أعضاء قانونيون في جمعيات معروفة",
    details:
      "معيار لإثبات القدرة على توفير المنتجات والخدمات التي تلبي متطلبات العملاء والمتطلبات التنظيمية باستمرار.",
    all: "الجميع",
    backend: "الخلفية",
    frontend: "نهاية المقدمة",
    database: "قاعدة البيانات",
    cloud: "سحاب ",
    testing: "اختبارات",
    devops: "مطور",
    design: "تصميم",
    mobileApp: "تطبيق الهاتف المحمول",
  },
  services: {
    title: "اكتشف خدماتنا",
    subtitle: "اختر أفضل شركة لخدمات تكنولوجيا المعلومات",
    details:
      "عند تصميم حل ، نجعل حل المشكلات أولويتنا القصوى مع الحفاظ على نفس القدر من التركيز على الجماليات.",
  },
  termsOfUse: {
    title: "شروط الاستخدام",
    subtitle: "آخر تحديث في 31 أغسطس 2023",
    details:
      'مرحبا بكم في موقع نوروز تكنولوجيز المحدودة. يرجى قراءة وفهم شروط الاستخدام هذه ("الشروط") بعناية قبل استخدام موقعنا. من خلال الوصول إلى موقعنا أو استخدامه، فإنك توافق على الالتزام بهذه الشروط. إذا كنت لا توافق على هذه الشروط، يرجى عدم استخدام موقعنا.',
    acceptanceOfTermsTitle: "قبول الشروط",
    acceptanceOfTermsDetails:
      "باستخدام هذا الموقع، فإنك تقر بأنك قد قرأت هذه الشروط وفهمتها ووافقت على الالتزام بها. إذا كنت لا توافق على هذه الشروط، يرجى الامتناع عن استخدام موقعنا.",
    useOfTheWebsiteTitle: "استخدام الموقع",
    useOfTheWebsiteDetails:
      "يمكنك استخدام موقعنا الإلكتروني للأغراض الشخصية وغير التجارية فقط. لا يجوز لك استخدام موقعنا الإلكتروني لأي غرض غير قانوني أو غير مصرح به، بما في ذلك على سبيل المثال لا الحصر انتهاك أي قوانين أو لوائح معمول بها.",
    intellectualPropertyTitle: "الملكية الفكرية",
    intellectualPropertyDetails:
      "جميع المحتويات والمواد والمعلومات الموجودة على هذا الموقع، بما في ذلك على سبيل المثال لا الحصر، النصوص والرسومات والشعارات والصور والمقاطع الصوتية والبرامج، هي ملك لشركة Newroz Technologies Limited ومحمية بموجب قوانين الملكية الفكرية. لا يجوز لك استخدام أو إعادة إنتاج أو توزيع أو تعديل أي محتوى من موقعنا دون موافقة كتابية مسبقة منا.",
    privacyTitle: "خصوصية",
    privacyDetails:
      "يخضع استخدامك لموقعنا الإلكتروني أيضًا لسياسة الخصوصية الخاصة بنا، والتي يمكن العثور عليها سياسة الخصوصية. يرجى مراجعة سياسة الخصوصية الخاصة بنا لفهم ممارساتنا فيما يتعلق بجمع واستخدام معلوماتك الشخصية.",
    userConductTitle: "سلوك المستخدم",
    userConductDetails1: "عند استخدام موقعنا، فإنك توافق على:",
    userConductDetails2:
      "أ. عدم المشاركة في أي نشاط قد يؤدي إلى الإضرار بوظائف موقعنا أو تعطيلها أو إثقالها أو إضعافها.",
    userConductDetails3:
      "ب. عدم محاولة الوصول غير المصرح به إلى موقعنا الإلكتروني أو أي أنظمة أو شبكات مرتبطة به.",
    userConductDetails4:
      "ج. عدم نقل أي فيروسات أو برامج ضارة أو أكواد ضارة عبر موقعنا.",
    userConductDetails5:
      "د. عدم استخدام موقعنا لأي أغراض غير قانونية أو غير أخلاقية.",
    userConductDetails6: "ه. روابط لمواقع الطرف الثالث.",
    userConductDetails7:
      "قد يحتوي موقعنا على روابط لمواقع طرف ثالث. يتم توفير هذه الروابط لراحتك، ونحن لسنا مسؤولين عن محتوى أو ممارسات مواقع الطرف الثالث هذه. يرجى مراجعة الشروط وسياسات الخصوصية لهذه المواقع قبل استخدامها.",
    disclaimerOfWarrantiesTitle: "التنصل من الضمانات",
    disclaimerOfWarrantiesDetails:
      "إن استخدامك لموقعنا الإلكتروني هو على مسؤوليتك الخاصة. نحن لا نقدم أي تعهدات أو ضمانات بشأن دقة أو اكتمال أو موثوقية المحتوى الموجود على موقعنا. نحن نخلي مسؤوليتنا من جميع الضمانات، سواء كانت صريحة أو ضمنية، بما في ذلك على سبيل المثال لا الحصر ضمانات القابلية للتسويق والملاءمة لغرض معين.",
    limitationOfLiabilityTitle: "تحديد المسؤولية",
    limitationOfLiabilityDetails:
      "إلى أقصى حد يسمح به القانون، لن تتحمل شركتك والشركات التابعة لها ومسؤولوها ومديروها وموظفوها ووكلاؤها المسؤولية عن أي أضرار مباشرة أو غير مباشرة أو عرضية أو خاصة أو تبعية تنشأ عن أو فيما يتعلق باستخدامك لموقعنا الإلكتروني.",
    indemnificationTitle: "التعويض",
    indemnificationDetails:
      "أنت توافق على تعويض شركتك والشركات التابعة لها ومسؤوليها ومديريها وموظفيها ووكلائها وحمايتها من أي مطالبات أو خسائر أو التزامات أو نفقات أو أضرار، بما في ذلك الرسوم القانونية، الناشئة عن أو المتعلقة باستخدامك لموقعنا الإلكتروني أو أي انتهاك لهذه الشروط.",
    changesToTermsTitle: "التغييرات في الشروط",
    changesToTermsDetails:
      "نحن نحتفظ بالحق في تعديل هذه الشروط في أي وقت دون سابق إنذار. إن استمرارك في استخدام موقعنا بعد هذه التغييرات سيشكل موافقتك على الشروط المعدلة.",
    governingLawTitle: "القانون الذي يحكم",
    governingLawdetails:
      "تخضع هذه الشروط وتفسر وفقًا لقوانين السلطة القضائية في بنغلاديش. أي نزاعات تنشأ عن أو تتعلق بهذه الشروط أو باستخدامك لموقعنا الإلكتروني تخضع للاختصاص القضائي الحصري لمحاكم بنغلاديش.",
    contactUsTitle: "اتصل بنا",
    contactUsDetails:
      "إذا كان لديك أي أسئلة أو استفسارات بشأن هذه الشروط، يرجى الاتصال بنا على اتصال.",
    footerText:
      "باستخدام موقعنا، فإنك تقر بأنك قد قرأت شروط الاستخدام هذه وفهمتها ووافقت عليها. إذا كنت لا توافق على هذه الشروط، يرجى التوقف عن استخدام موقعنا. شكرا لكم لزيارة موقع نوروز تكنولوجيز المحدودة.",
  },
  privacyPolicy: {
    title: "سياسة الخصوصية",
    subtitle: "آخر تحديث في 31 أغسطس 2023",
    details:
      "تلتزم شركة Newroz Technologies Limited بحماية خصوصيتك. تشرح سياسة الخصوصية هذه كيف نقوم بجمع معلوماتك الشخصية واستخدامها والكشف عنها وحمايتها عند زيارتك لموقعنا الإلكتروني أو استخدام خدماتنا. يرجى قراءة سياسة الخصوصية هذه بعناية لفهم ممارساتنا فيما يتعلق بمعلوماتك الشخصية.",
    informationWeCollectTitle: "المعلومات التي نجمعها",
    informationWeCollectDetails1:
      "أ. المعلومات التي تقدمها: عندما تستخدم موقعنا أو تتفاعل معنا، يجوز لك أن تزودنا طوعًا بمعلومات شخصية، بما في ذلك على سبيل المثال لا الحصر، اسمك وعنوان بريدك الإلكتروني وأي معلومات أخرى ترسلها من خلال نماذج الاتصال الخاصة بنا أو قنوات الاتصال الأخرى.",
    informationWeCollectDetails2:
      "ب. المعلومات الآلية: قد نقوم بجمع معلومات معينة تلقائيًا عند زيارتك لموقعنا على الويب، مثل عنوان IP الخاص بك ونوع المتصفح ونظام التشغيل وسلوك التصفح. قد نستخدم أيضًا ملفات تعريف الارتباط والتقنيات المشابهة لجمع البيانات حول تفاعلك مع موقعنا.",
    howWeUseYourInformationTitle: "كيف نستخدم معلوماتك",
    howWeUseYourInformationDetails1:
      "قد نستخدم المعلومات التي نجمعها للأغراض التالية:",
    howWeUseYourInformationDetails2: "أ. لتوفير وصيانة موقعنا وخدماتنا.",
    howWeUseYourInformationDetails3:
      "ب. للرد على استفساراتك وتقديم دعم العملاء.",
    howWeUseYourInformationDetails4:
      "ج. لإرسال الرسائل الإخبارية والتحديثات والاتصالات التسويقية إليك إذا اخترت تلقيها. يمكنك إلغاء الاشتراك في هذه الاتصالات في أي وقت.",
    howWeUseYourInformationDetails5:
      "د. لتحسين موقعنا وخدماتنا وتحليل كيفية تفاعل المستخدمين مع موقعنا.",
    howWeUseYourInformationDetails6:
      "ه. لحماية موقعنا ومستخدمينا، والتحقيق في الاحتيال ومنعه، وإنفاذ شروطنا وسياساتنا.",
    disclosureOfYourInformationTitle: "الكشف عن المعلومات الخاصة بك",
    disclosureOfYourInformationDetails1:
      "قد نشارك معلوماتك الشخصية مع أطراف ثالثة في الظروف التالية:",
    disclosureOfYourInformationDetails2:
      "أ. مع مقدمي الخدمات وشركاء الأعمال الذين يساعدوننا في تقديم موقعنا وخدماتنا.",
    disclosureOfYourInformationDetails3:
      "ب. استجابة للطلبات القانونية، مثل أوامر المحكمة أو مذكرات الاستدعاء، للامتثال للقوانين واللوائح المعمول بها، أو لحماية حقوقنا أو خصوصيتنا أو سلامتنا أو ممتلكاتنا.",
    disclosureOfYourInformationDetails4:
      "ج. فيما يتعلق بعملية الدمج أو البيع أو الاستحواذ على كل أو جزء من شركتنا.",
    disclosureOfYourInformationDetails5: "د. بموافقتك أو بتوجيه منك.",
    securityOfYourInformationTitle: "أمن المعلومات الخاصة بك",
    securityOfYourInformationDetails:
      "نحن نتخذ تدابير معقولة لحماية معلوماتك الشخصية من الوصول غير المصرح به، والكشف، والتغيير، والتدمير. ومع ذلك، لا يوجد نقل للبيانات عبر الإنترنت أو التخزين الإلكتروني آمن تمامًا. يرجى توخي الحذر عند تقديم المعلومات الشخصية عبر الإنترنت.",
    yourChoicesTitle: "اختياراتك",
    yourChoicesDetails1: "لديك الخيارات التالية فيما يتعلق بمعلوماتك الشخصية:",
    yourChoicesDetails2:
      "أ. يمكنك تحديث أو تصحيح معلوماتك الشخصية عن طريق الاتصال بنا.",
    yourChoicesDetails3:
      "ب. يمكنك إلغاء الاشتراك في تلقي الاتصالات التسويقية منا باتباع تعليمات إلغاء الاشتراك الواردة في رسائل البريد الإلكتروني لدينا.",
    childrensPrivacyTitle: "خصوصية الأطفال",
    childrensPrivacyDetails:
      "موقعنا وخدماتنا غير موجهة للأطفال دون سن 13 عامًا. نحن لا نجمع معلومات شخصية عن عمد من الأطفال دون سن 13 عامًا. إذا كنت تعتقد أننا جمعنا هذه المعلومات عن غير قصد، فيرجى الاتصال بنا، وسنتخذ الخطوات المناسبة لحذفها .",
    changesToThisPrivacyPolicyTitle: "التغييرات على سياسة الخصوصية هذه",
    changesToThisPrivacyPolicyDetails:
      'نحن نحتفظ بالحق في تعديل سياسة الخصوصية هذه في أي وقت. سيتم نشر أي تغييرات على هذه الصفحة، وسيتم مراجعة تاريخ "آخر تحديث" وفقًا لذلك.',
    contactUsTitle: "اتصل بنا",
    contactUsDetails:
      "إذا كان لديك أي أسئلة أو استفسارات بشأن هذه الشروط، يرجى الاتصال بنا على اتصال.",
    footerText:
      "باستخدام موقعنا وخدماتنا، فإنك توافق على الممارسات الموضحة في سياسة الخصوصية هذه. شكرًا لاختيارك نوروز تكنولوجيز المحدودة. خصوصيتك مهمة بالنسبة لنا، ونحن ملتزمون بحمايتها.",
  },
  sitemap: {
    title: "خريطة الموقع",
    subtitle: "آخر تحديث في 31 أغسطس 2023",
    pagesTitle: "الصفحات",
    pages: [
      "بيت",
      "خدمات",
      "منتجات",
      "دراسات الحالة",
      "شركة",
      "اتصال",
      "حياة مهنية",
    ],
    خدمات: "Services",
    services: [
      "تطوير تطبيقات الهاتف المحمول",
      "تطوير البرمجيات المخصصة",
      "خدمات ديفوبس",
      "بلوكتشين",
      "اختبار البرمجيات",
      "تطوير تطبيقات الويب",
      "تصميم واجهة المستخدم/تجربة المستخدم",
      "خدمات التحقق الإلكتروني من KYC",
      "الاستشارات السحابية",
    ],
    productsTitle: "منتجات",
    products: [
      "المحفظة الإلكترونية FastPay",
      "اتحاد دالكرد لكرة القدم",
      "الاتصال السريع (نقاط البيع)",
      "حل KYC الإلكتروني",
      "العملات المشفرة - IQDX",
      "تمييز الوجوه",
    ],
    CaseStudiesTitle: "دراسات الحالة",
    caseStudies: [
      "المحفظة الإلكترونية FastPay",
      "اتحاد دالكرد لكرة القدم",
      "الاتصال السريع (نقاط البيع)",
      "حل KYC الإلكتروني",
      "العملات المشفرة - IQDX",
      "تمييز الوجوه",
    ],
  },
  contactFindUs: {
    title: "Find Us",
    subtitle: "Innovative Solutions For A Digital World",
    card1: {
      title: "Erbil, Kurdistan",
      address: "Iraq",
      phone: "+964 750 939 3344",
      phone2: "+964 751 840 3143",
    },
    card2: {
      title: "Dubai Silicon Oasis",
      address: "Dubai - United Arab Emirates",
      phone: "+971 56 626 6825",
      phone2: "+971 (56) 626 6825",
    },
    card3: {
      title: "Mirpur DOHS, Dhaka",
      address: "Bangladesh",
      phone: "+88 014 0119 9557",
      phone2: "+88 (017) 1327 3847",
    },
  },
  contactFollowUs: {
    title: "Follow Us",
    subtitle: "contact@newroztech.com",
    followUs: "Follow Us",
    letsConnect: "Let’s Connect",
    subscribe: "Subscribe",
  },
  // ---------------------company section end------------------
  // ---------------------career section start------------------
  careerTopSection: {
    title: "كن جزءًا من فريق نوروز المتنامي.",
    subtitle: "اصنع تأثيرًا بفعل ما تحب.",
    card1: {
      title: "وظيفة مصمم واجهة المستخدم",
      details:
        "نحن نبحث عن مصمم واجهة مستخدم ذو خبرة وموهوب لتصميم وتشكيل منتجات وتجارب فريدة تتمحور حول المستخدم. سيكون لدى المرشح المثالي خبرة في العمل ضمن فرق Agile، مع المطورين ومصممي تجربة المستخدم ومؤلفي النصوص. ستكون قادرًا على اتخاذ قرارات تصميم مدروسة وترجمة أي رحلة تجربة مستخدم معينة إلى تفاعل سلس وبديهي.",
    },
    paginationText: "اصنع تأثيرًا بفعل ما تحب.",
  },
  careerJobTypeSection: {
    type1: "All",
    type2: "Engineering (6)",
    type3: "Design (2)",
    type4: "FinTech (3)",
    type5: "Testing (1)",
    type6: "DevOps",
    type7: "frontend developer",
  },
  careerLocationSection: {
    type1: "Dhaka",
    type1: "Kurdistan",
    type1: "Dubai",
  },
  careerHowToApply: {
    title: "كيفية التقديم",
    subtitle:
      "هل أنت مستعد للشروع في رحلة مثيرة مع Newroz Technologies Limited؟ إليك كيفية التقديم للانضمام إلى فريقنا:",
    details1:
      "تصفح وظائفنا المفتوحة على بوابة التوظيف الخاصة بنا وحدد الدور الذي يناسب مهاراتك وخبراتك وتطلعاتك المهنية.",
    details2:
      "قم بإعداد سيرتك الذاتية، وتأكد من أنها تسلط الضوء على خبراتك وإنجازاتك ذات الصلة.",
    details3:
      "اكتب خطابًا تقديميًا مقنعًا يوضح شغفك بالتكنولوجيا ويشرح سبب كونك مناسبًا تمامًا لهذا الدور.",
    details4:
      "أرسل طلبك من خلال بوابتنا الإلكترونية، وتأكد من تضمين سيرتك الذاتية وخطاب التقديم وأي وثائق إضافية مطلوبة في إعلان الوظيفة.",
  },
  careerTheRecruitmentProcess: {
    title: "عملية التوظيف",
    subtitle:
      "في شركة Newroz Technologies Limited، نحن نفخر بعملية التوظيف الشاملة لدينا، والمصممة لتحديد أفضل المواهب وضمان الملاءمة الجيدة لكل من المرشح وشركتنا. إليك ما يمكنك توقعه عند التقديم:",
    details1:
      "مراجعة الطلب: سيقوم فريق الموارد البشرية لدينا بمراجعة طلبك بعناية، وتقييم مهاراتك وخبراتك ومدى ملاءمتك لهذا الدور.",
    details2:
      "فحص الهاتف: إذا كان طلبك يلبي متطلباتنا، فستتم دعوتك للمشاركة في فحص الهاتف. هذه فرصة لنا لمعرفة المزيد عنك ولطرح أي أسئلة قد تكون لديك حول الدور أو شركتنا.",
    details3:
      "التقييم الفني: اعتمادًا على المنصب، قد يُطلب منك إكمال تقييم فني لإثبات كفاءتك في مهارات أو تقنيات معينة.",
    details4:
      "مقابلات شخصية أو مقابلات عبر الفيديو، ستتم دعوة المرشحين المدرجين في القائمة المختصرة لإجراء مقابلات شخصية أو عبر الفيديو، حيث ستلتقي بأعضاء فريقنا لمناقشة خبرتك ومهاراتك وأهدافك المهنية بمزيد من التفاصيل.",
    details5:
      "الفحوصات المرجعية: إذا تقدمت إلى المراحل النهائية من عملية التوظيف، فسنجري فحوصات مرجعية للتحقق من تاريخ التوظيف الخاص بك والحصول على تعليقات من مديريك أو زملائك السابقين.",
    details6:
      "العرض والتأهيل: سيتلقى المرشحون الناجحون عرضًا رسميًا يوضح شروط وأحكام التوظيف. عند القبول، سيتم الترحيب بك في فريقنا والبدء في عملية الإعداد، حيث ستتعلم المزيد عن ثقافة شركتنا وقيمها وتوقعاتها.",
  },
  careerEmbraceTheFuture: {
    title: "احتضن المستقبل في شركة نوروز تكنولوجيز المحدودة",
    details1:
      "في شركة نوروز تكنولوجيز المحدودة، نحن ملتزمون بخلق بيئة عمل شاملة وداعمة ومليئة بالتحديات تمكن أعضاء فريقنا من تحقيق إمكاناتهم الكاملة. إذا كنت فردًا متحمسًا وموهوبًا ومتحمسًا لتشكيل مستقبل التكنولوجيا، فنحن ندعوك للتقدم لشغل أحد وظائفنا المفتوحة والبدء في رحلة مهنية مثيرة معنا.",
    details2:
      "لا تفوت هذه الفرصة للانضمام إلى فريق من المحترفين المتفانين الذين يعملون في طليعة الابتكار. تقدم بطلبك الآن واكتشف كيف يمكنك المساهمة في نجاح شركة نوروز تكنولوجيز المحدودة، ومعًا يمكننا بناء مستقبل أكثر إشراقًا لعملائنا وشركتنا وعالم التكنولوجيا.",
  },
  careerDetails: {
    title: "وظيفة مصمم واجهة المستخدم الابن",
    subtitle: "خبرة العمل 3-5 سنوات",
  },
  careerJobBrief: {
    title: "ملخص الوظيفة",
    subtitle:
      "نحن نبحث عن مصمم واجهة مستخدم موهوب لإنشاء تجارب مستخدم مذهلة. يجب أن يكون لدى المرشح المثالي عين للتصميم النظيف والمتقن، وأن يمتلك مهارات فائقة في واجهة المستخدم وأن يكون قادرًا على ترجمة المتطلبات عالية المستوى إلى تدفقات تفاعلية وأعمال فنية، وتحويلها إلى واجهات مستخدم جميلة وبديهية وعملية.",
  },
  careerResponsibilities: {
    title: "المسؤوليات",
    details1:
      "تعاون مع إدارة المنتج والهندسة لتحديد وتنفيذ الحلول المبتكرة لتوجيه المنتج والمرئيات والخبرة",
    details2:
      "تنفيذ جميع مراحل التصميم المرئي بدءًا من المفهوم وحتى التسليم النهائي وحتى الهندسة",
    details3:
      "تصور الأفكار الأصلية التي تضفي البساطة وسهولة الاستخدام على حواجز التصميم المعقدة",
    details4:
      "قم بإنشاء إطارات سلكية ولوحات قصصية وتدفقات المستخدم وتدفقات العمليات وخرائط الموقع لتوصيل التفاعل وأفكار التصميم بشكل فعال",
    details5:
      "تقديم التصاميم والتسليمات الرئيسية والدفاع عنها للأقران وأصحاب المصلحة على المستوى التنفيذي",
    details6: "إجراء بحث المستخدم وتقييم ملاحظات المستخدم",
    details7: "وضع وتعزيز المبادئ التوجيهية للتصميم وأفضل الممارسات والمعايير",
  },
  careerRequirementsAndSkills: {
    title: "المتطلبات والمهارات",
    details1: "تجربة واجهة مستخدم مثبتة",
    details2: "مهارات تصميم واجهة المستخدم التي يمكن إثباتها مع محفظة قوية",
    details3:
      "خبرة قوية في إنشاء الإطارات السلكية واللوحات المصورة وتدفقات المستخدم وتدفقات العمليات وخرائط الموقع",
    details4:
      "إتقان استخدام Photoshop أو Illustrator أو OmniGraffle أو أدوات التصميم المرئي والتأطير السلكي الأخرى",
    details5: "إتقان HTML وCSS وJavaScript للنماذج الأولية السريعة.",
    details6: "مهارات تصميم مرئي ممتازة مع حساسية لتفاعل نظام المستخدم",
    details7: "القدرة على تقديم تصميماتك وبيع حلولك لمختلف أصحاب المصلحة.",
    details8: "القدرة على حل المشاكل بشكل خلاق وفعال",
    details9: "مطلع على أحدث اتجاهات وتقنيات وتقنيات واجهة المستخدم",
    details10:
      "بكالوريوس / ماجستير في التفاعل بين الإنسان والحاسوب، أو تصميم التفاعل، أو ما يتصل بذلك",
    details11: "خبرة في العمل في عملية تطوير Agile/Scrum",
  },
  // ---------------------career section end------------------
  pageTitles: {
    HomepageTitle: "بيت",
    companypageTitle: "دراسات الحالة",
    servicespageTitle: "خدمات",
    caseStudiespageTitle: "دراسات الحالة",
    contactpageTitle: "اتصال",
    careerpageTitle: "شركة",
    productspageTitle: "منتجات",
    termsOfUsepageTitle: "شروط الاستخدام",
    privacyPolicypageTitle: "سياسة الخصوصية",
    SitemappageTitle: "خريطة الموقع",
    productDetailspageTitle: "تفاصيل المنتج",
    serviceDetails: "تفاصيل الخدمة",
    termsAndConditions: "البنود و الظروف",
    details: "تفاصيل",
  },
  buttonText: {
    discoverMoreText: "إكتشف أكثر",
    visitNowText: "زيارة الآن",
    anyBusynessIdea: "أي فكرة عن الانشغال",
    learnMore: "يتعلم أكثر",
    checkDetails: "تحقق من التفاصيل",
    submitNow: "أرسل الآن",
    applyNow: "قدم الآن",
  },
};
export default kr;
